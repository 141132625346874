import { type UserRole, internalAndContractorRoles, roles } from './roles'

/**
 * `customer-user`s only have access to URLs prefixed with "my-hos"
 */
const rolesWithoutCustomerUser = roles.options.filter(r => r !== 'customer-user')

// Permissions for fixed, static paths that do not contain wildcards / dynamic parameters
export const PERMISSIONS_BY_PATH: Record<string, readonly UserRole[]> = {
  '/': rolesWithoutCustomerUser,
  '/auth/logout': ['no-role'],

  '/tasks': internalAndContractorRoles,

  '/reporting': ['admin'],

  '/overview': ['project-manager', 'department-lead', 'guest', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'bookkeeping', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter', 'freelancer-sales', 'partner-sales', 'logistics-employee'],
  '/overview/machinery': ['project-manager', 'department-lead', 'guest', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'bookkeeping', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter', 'freelancer-sales', 'partner-sales', 'logistics-employee'],
  '/overview/storage-tool': ['project-manager', 'department-lead', 'guest', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'bookkeeping', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter', 'freelancer-sales', 'logistics-employee'],
  '/overview/foreign-machinery': ['project-manager', 'department-lead', 'consignee', 'sales-employee', 'workshop-employee'],

  '/freelancer-sales': ['admin', 'freelancer-sales'],
  '/freelancer-sales/released-machinery': ['admin'],
  '/freelancer-sales/sale/current/offer': ['freelancer-sales'],
  '/freelancer-sales/sale/current/order': ['freelancer-sales'],
  '/freelancer-sales/sale/all': ['admin', 'freelancer-sales'],

  '/machinery-type': ['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],
  '/machinery-type/maintain': ['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],

  '/machinery-creation': ['project-manager', 'department-lead', 'consignee', 'receptionist', 'special-role-benni'],
  '/machinery-creation/creation': ['project-manager', 'department-lead', 'special-role-benni'],
  '/machinery-creation/delivery': ['project-manager', 'department-lead', 'consignee', 'receptionist', 'special-role-benni'],
  '/machinery-creation/reception': ['project-manager', 'department-lead', 'consignee', 'receptionist', 'special-role-benni'],
  '/machinery-creation/marking': ['project-manager', 'department-lead', 'consignee', 'receptionist', 'special-role-benni'],
  '/machinery-creation/approval': ['project-manager', 'department-lead', 'special-role-benni'],

  '/storage-tool-creation': ['project-manager', 'department-lead', 'consignee', 'receptionist', 'storage-lead', 'storage-employee', 'special-role-benni', 'logistics-employee'],
  '/storage-tool-creation/creation': ['project-manager', 'department-lead', 'consignee', 'receptionist', 'storage-lead', 'storage-employee', 'special-role-benni', 'logistics-employee'],
  '/storage-tool-creation/marking': ['project-manager', 'department-lead', 'consignee', 'receptionist', 'storage-lead', 'storage-employee', 'special-role-benni', 'logistics-employee'],
  '/storage-tool-creation/approval': ['project-manager', 'department-lead', 'storage-lead', 'storage-employee', 'special-role-benni', 'logistics-employee'],

  '/storage-tool-bundle': ['department-lead', 'rental-employee', 'special-role-benni'],

  '/set-overview': ['project-manager', 'department-lead', 'consignee', 'receptionist', 'storage-lead', 'storage-employee', 'special-role-benni', 'rental-employee', 'logistics-employee'],

  '/marketing': ['project-manager', 'department-lead', 'marketing-employee', 'bookkeeping'],
  '/marketing/edit': ['project-manager', 'department-lead', 'marketing-employee', 'bookkeeping'],
  '/marketing/preparation': ['project-manager', 'department-lead', 'marketing-employee', 'bookkeeping'],

  '/calendar': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],

  '/service': ['project-manager', 'department-lead', 'consignee', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],
  '/service/machinery-accessory': ['project-manager', 'department-lead', 'consignee', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],
  '/service/machinery': ['project-manager', 'department-lead', 'consignee', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],
  '/service/repair-machinery': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],
  '/service/repair-storage-tool': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],
  '/service/sale-order': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],
  '/service/sale-issuance': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],

  '/offer-templates': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'service-project-employee', 'service-project-fitter'],
  '/offer-templates/position-templates': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'service-project-employee', 'service-project-fitter'],
  '/offer-templates/position-template-bundles': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'service-project-employee', 'service-project-fitter'],

  '/customer-overview': ['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'service-project-employee', 'service-project-fitter', 'special-role-benni'],
  '/customer-overview/customers': ['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'service-project-employee', 'service-project-fitter', 'special-role-benni'],
  '/customer-overview/customer-user': ['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'service-project-employee', 'service-project-fitter', 'special-role-benni'],
  '/customer-overview/other-invoice-recipients': ['project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'bookkeeping', 'storage-lead', 'storage-employee', 'service-project-employee', 'service-project-fitter', 'special-role-benni'],

  '/logistics-planning': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],

  '/delivery-vehicle': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'storage-lead', 'storage-employee', 'special-role-benni'],
  '/driver/overview': ['project-manager', 'department-lead', 'disponent', 'driver', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter', 'workshop-employee', 'logistics-employee'],

  '/offer/edit': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'workshop-employee', 'bookkeeping', 'marketing-employee', 'receptionist', 'service-project-employee', 'service-project-fitter', 'disponent', 'freelancer-sales', 'consignee', 'driver', 'storage-lead', 'storage-employee', 'special-role-benni', 'logistics-employee'],
  '/offer/create': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'workshop-employee', 'bookkeeping', 'marketing-employee', 'receptionist', 'service-project-employee', 'service-project-fitter', 'disponent', 'freelancer-sales', 'consignee', 'driver', 'storage-lead', 'storage-employee', 'special-role-benni', 'logistics-employee'],

  '/sale': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'workshop-employee', 'bookkeeping', 'special-role-benni'],
  '/sale/offer': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'workshop-employee', 'bookkeeping', 'special-role-benni'],
  '/sale/order': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'workshop-employee', 'bookkeeping', 'special-role-benni'],
  '/sale/cancelled': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'workshop-employee', 'bookkeeping', 'special-role-benni'],
  '/sale/completed': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'workshop-employee', 'bookkeeping', 'special-role-benni'],

  '/special-order': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'storage-lead', 'storage-employee', 'workshop-employee', 'bookkeeping', 'special-role-benni', 'marketing-employee', 'receptionist', 'service-project-employee', 'service-project-fitter', 'disponent'],
  '/special-order/offer': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'storage-lead', 'storage-employee', 'workshop-employee', 'bookkeeping', 'special-role-benni', 'marketing-employee', 'receptionist', 'service-project-employee', 'service-project-fitter', 'disponent'],
  '/special-order/order': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'storage-lead', 'storage-employee', 'workshop-employee', 'bookkeeping', 'special-role-benni', 'marketing-employee', 'receptionist', 'service-project-employee', 'service-project-fitter', 'disponent'],
  '/special-order/cancelled': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'storage-lead', 'storage-employee', 'workshop-employee', 'bookkeeping', 'special-role-benni', 'marketing-employee', 'receptionist', 'service-project-employee', 'service-project-fitter', 'disponent'],

  '/rental': ['project-manager', 'department-lead', 'rental-employee', 'workshop-employee', 'bookkeeping'],
  '/rental/offer': ['project-manager', 'department-lead', 'rental-employee', 'workshop-employee', 'bookkeeping'],
  '/rental/order': ['project-manager', 'department-lead', 'rental-employee', 'workshop-employee', 'bookkeeping'],
  '/rental/cancelled': ['project-manager', 'department-lead', 'rental-employee', 'workshop-employee', 'bookkeeping'],
  '/rental/completed': ['project-manager', 'department-lead', 'rental-employee', 'workshop-employee', 'bookkeeping'],

  '/service-project': ['project-manager', 'department-lead', 'service-project-employee', 'service-project-fitter', 'rental-employee', 'bookkeeping', 'consignee', 'receptionist', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'storage-lead', 'storage-employee', 'special-role-benni', 'logistics-employee'],
  '/service-project/planning': ['project-manager', 'department-lead', 'service-project-employee', 'service-project-fitter', 'rental-employee', 'bookkeeping', 'consignee', 'receptionist', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'storage-lead', 'storage-employee', 'special-role-benni', 'logistics-employee'],
  '/service-project/fitter-issuance': ['project-manager', 'department-lead', 'service-project-employee', 'service-project-fitter', 'rental-employee', 'bookkeeping', 'consignee', 'receptionist', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'storage-lead', 'storage-employee', 'special-role-benni', 'logistics-employee'],
  '/service-project/inquiry': ['project-manager', 'department-lead', 'service-project-employee', 'service-project-fitter', 'rental-employee', 'bookkeeping', 'consignee', 'receptionist', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'storage-lead', 'storage-employee', 'special-role-benni', 'logistics-employee'],
  '/service-project/offer': ['project-manager', 'department-lead', 'service-project-employee', 'service-project-fitter', 'rental-employee', 'bookkeeping', 'consignee', 'receptionist', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'storage-lead', 'storage-employee', 'special-role-benni', 'logistics-employee'],
  '/service-project/order': ['project-manager', 'department-lead', 'service-project-employee', 'service-project-fitter', 'rental-employee', 'bookkeeping', 'consignee', 'receptionist', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'storage-lead', 'storage-employee', 'special-role-benni', 'logistics-employee'],
  '/service-project/completed': ['project-manager', 'department-lead', 'service-project-employee', 'service-project-fitter', 'rental-employee', 'bookkeeping', 'consignee', 'receptionist', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'storage-lead', 'storage-employee', 'special-role-benni', 'logistics-employee'],
  '/service-project/cancelled': ['project-manager', 'department-lead', 'service-project-employee', 'service-project-fitter', 'rental-employee', 'bookkeeping', 'consignee', 'receptionist', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'storage-lead', 'storage-employee', 'special-role-benni', 'logistics-employee'],
  '/service-project/external': ['external-service-project-contractor'],

  '/invoice': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'bookkeeping'],
  '/invoice/preparation': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'bookkeeping'],
  '/invoice/payment': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'bookkeeping'],
  '/invoice/archive': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'bookkeeping'],

  '/logistics': ['project-manager', 'department-lead', 'disponent', 'storage-lead', 'storage-employee', 'workshop-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],
  '/logistics/issuance': ['project-manager', 'department-lead', 'disponent', 'storage-lead', 'storage-employee', 'workshop-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],
  '/logistics/issued': ['project-manager', 'department-lead', 'disponent', 'storage-lead', 'storage-employee', 'workshop-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],
  '/logistics/terminations': ['project-manager', 'department-lead', 'disponent', 'storage-lead', 'storage-employee', 'workshop-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],
  '/logistics/cancelled': ['storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],
  '/logistics/collections': ['project-manager', 'department-lead', 'disponent', 'storage-lead', 'storage-employee', 'workshop-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],
  '/logistics/collected': ['project-manager', 'department-lead', 'disponent', 'storage-lead', 'storage-employee', 'workshop-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],
  '/logistics/store': ['project-manager', 'department-lead', 'disponent', 'storage-lead', 'storage-employee', 'workshop-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter'],

  '/internal-costs': ['workshop-employee', 'marketing-employee', 'disponent', 'rental-employee', 'department-lead', 'service-project-employee', 'service-project-fitter'],
  '/internal-costs/machinery': ['workshop-employee', 'marketing-employee', 'disponent', 'rental-employee', 'department-lead', 'service-project-employee', 'service-project-fitter'],
  '/internal-costs/order': ['workshop-employee', 'marketing-employee', 'disponent', 'rental-employee', 'department-lead', 'service-project-employee', 'service-project-fitter'],

  '/tasks/create': rolesWithoutCustomerUser,
  '/tasks/edit': rolesWithoutCustomerUser,
  '/tasks/inquiry': ['no-role'],

  // Rules: https://github.com/sidestream-tech/hanselmann-os/issues/2610
  '/external-storage-administration': ['department-lead'],
  '/external-storage-inbound/request': ['department-lead', 'storage-lead', 'logistics-employee', 'ext-storage-user'],
  '/external-storage-inbound/store': ['department-lead', 'storage-lead', 'logistics-employee'],
  '/external-storage-inbound/approve': ['department-lead'],
  '/external-storage-outbound/request': ['department-lead', 'storage-lead', 'logistics-employee', 'ext-storage-user'],
  '/external-storage-outbound/load': ['department-lead', 'storage-lead', 'logistics-employee'],
  '/external-storage': ['department-lead', 'storage-lead', 'logistics-employee', 'ext-storage-user'],
  '/external-storage/storage-product': ['ext-storage-user'],
  '/external-storage/positions-in-storage': ['ext-storage-user'],
  '/external-storage/retrieved-positions': ['ext-storage-user'],
  '/external-storage-inbound': ['department-lead', 'storage-lead', 'logistics-employee', 'ext-storage-user'],
  '/external-storage-outbound': ['department-lead', 'storage-lead', 'logistics-employee', 'ext-storage-user'],

  // Pages for customer-module defined in /layers/customer-module
  // Added in https://github.com/sidestream-tech/hanselmann-os/pull/3046
  '/my-hos': ['customer-user'],
}

// Permissions for wildcard-paths
const PERMISSIONS_BY_PATH_PREFIX: Record<string, readonly UserRole[]> = {
  '/machinery-accessory/': ['no-role'],
  '/machinery/': ['no-role'],
  '/logistics-details/': ['no-role'],
  '/set/': ['no-role'],
  '/offer-reporting/': ['no-role'],
  '/service/repair-machinery/': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'special-role-benni'],
  '/service/repair-storage-tool/': ['project-manager', 'department-lead', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'special-role-benni'],
  '/driver/': ['project-manager', 'department-lead', 'disponent', 'driver', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter', 'workshop-employee', 'logistics-employee'],
  '/service-project/external/': ['external-service-project-contractor'],

  // Rules: https://github.com/sidestream-tech/hanselmann-os/issues/2610
  // https://github.com/sidestream-tech/hanselmann-os/issues/3020
  '/external-storage-administration/': ['department-lead'],
  '/external-storage/': ['department-lead', 'storage-lead', 'logistics-employee'],
  '/external-storage-position/': ['department-lead', 'storage-lead', 'logistics-employee', 'ext-storage-user'],
  '/inbound-logistics-task/': ['department-lead', 'storage-lead', 'logistics-employee', 'ext-storage-user'],
  '/external-storage-outbound/task/': ['department-lead', 'storage-lead', 'logistics-employee'],

  // Pages for customer-module defined in /layers/customer-module
  // Added in https://github.com/sidestream-tech/hanselmann-os/pull/3046
  '/my-hos/': ['customer-user'],
  '/my-hos/machinery/': ['no-role'],
}

const PERMISSIONS_BY_PATH_PREFIX_ARRAY = Object.entries(PERMISSIONS_BY_PATH_PREFIX)

/**
 * Check whether an app-side action is allowed.
 *
 * @param role Role of the logged in user. If `undefined` is passed it is assumed that the user is not logged in.
 * @param path Path of the page that the user is trying to access.
 */
export function can(role: UserRole, path: string) {
  // Admins can see all pages
  if (role === 'admin') {
    return true
  }

  let hasSeenARule = false

  // Exact match
  const pathPermissions = PERMISSIONS_BY_PATH[path]
  if (pathPermissions) {
    hasSeenARule = true

    // no-role means that even people without an account can see the page
    if (pathPermissions.includes('no-role') || pathPermissions.includes(role)) {
      return true
    }
  }

  // Prefix match
  for (const pathRule of PERMISSIONS_BY_PATH_PREFIX_ARRAY) {
    if (!path.startsWith(pathRule[0])) {
      continue
    }

    hasSeenARule = true

    // no-role means that even people without an account can see the page
    const roles = pathRule[1]
    if (roles.includes('no-role') || roles.includes(role)) {
      return true
    }
  }

  if (!hasSeenARule) {
    authLogger.warn(`No rules found for this path, denying access. Path is: ${path}`)
  }

  return false
}
