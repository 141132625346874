<script setup lang="ts">
import type { FreelancerSalesReleaseMachineryForFreelancerBase } from '~/types'

const { openReleaseMachineryForFreelancerSalesPopup: { data, close } } = useGlobalOpeners()

// Update mode
// https://github.com/sidestream-tech/hanselmann-os/issues/2112
const releaseConditionId = computed(() => (
  data.value?.mode === 'update'
    ? data.value.id
    : undefined
))
const prefilledValue = computed(() => {
  const data = freelancerMachineryRelation.data.value
  if (!data) {
    return
  }

  return {
    ...data,
    freelancerUserIds: [data.freelancerUserId],
    // Fixes FormKit pre-filling issue
    ts: Date.now(),
  }
})

const { freelancerSales } = useQuery()
const freelancers = freelancerSales.getFreelancers()
const freelancerMachineryRelation = freelancerSales.getReleaseConditionById(releaseConditionId)

const isLoading = computed(() => freelancers.isLoading.value && freelancerMachineryRelation.isLoading.value)

const freelancerOptions = computed(() => {
  const availableFreelancerOptions = freelancers.data.value
  if (!availableFreelancerOptions) {
    return {}
  }

  return Object.fromEntries(availableFreelancerOptions.map(({ id, name, email }) => [id, name ?? email]))
})

const { $trpc, queryClient, makeTrpcErrorToast, useMutation } = useMutationHelpers()
const notification = useNotification()
const releaseMachineriesForFreelancers = useMutation({
  mutationFn: $trpc.freelancerSales.releaseMachineriesForFreelancers.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Freigaben für die Maschinen konnten nur teilweise angewendet werden.' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['freelancerSales'] })
    notification.success({ title: `Gewünschte Freigaben wurden erfolgreich erstellt`, duration: 4500 })
    close()
  },
})

const updateMachineryReleasedForFreelancerCondition = useMutation({
  mutationFn: $trpc.freelancerSales.updateMachineryReleasedForFreelancerCondition.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Freigaben für die Maschinen konnten nur teilweise angewendet werden.' }),
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ['freelancerSales'] })
    notification.success({ title: 'Gewünschte Freigaben wurden erfolgreich erstellt', duration: 4500 })
    close()
  },
})

type SubmitFormData = FreelancerSalesReleaseMachineryForFreelancerBase & { freelancerUserIds: string[] }
function onFormSubmit(value: SubmitFormData) {
  const popupData = data.value
  if (!popupData) {
    return
  }

  if (popupData.mode === 'create') {
    releaseMachineriesForFreelancers.mutate({ ...value, machineryIds: popupData.machineryIds })
  } else {
    updateMachineryReleasedForFreelancerCondition.mutate({
      ...value,
      id: popupData.id,
    })
  }
}
</script>

<template>
  <ThePopup
    v-if="data"
    :show="true"
    title="Maschine(n) freigeben"
    @close="close"
  >
    <TheDataCard :is-loading="isLoading">
      <n-collapse class="mb-4">
        <n-collapse-item title="Maschine(n) Liste">
          <ul class="ml-8 list-disc grid grid-rows-1 grid-cols-5 grid-flow-auto">
            <li v-for="machineryId in data.machineryIds" :key="machineryId">
              {{ machineryId }}
            </li>
          </ul>
        </n-collapse-item>
      </n-collapse>
      <FormKit
        v-slot="{ value }"
        :key="prefilledValue?.ts"
        type="form"
        submit-label="Neue Konditionen speichern"
        :value="prefilledValue"
        @submit="onFormSubmit"
      >
        <TheDevOnlyNiceJson :value="value" />
        <FormKit
          name="freelancerUserIds"
          type="checkbox"
          label="Freelancer"
          :options="freelancerOptions"
          :disabled="data.mode !== 'create'"
          decorator-icon="happy"
          help="Wählen Sie die Freelancer aus, für die diese Freigabe gelten soll. Bereits bestehende Konditionen werden überschrieben."
          validation="required|min:1"
        />
        <FormKit
          name="priceFreelancerHasToPurchaseFor"
          type="number"
          step="0.01"
          min="0"
          label="Freelancer EK-Preis"
          placeholder="40000"
          validation="required"
          number="float"
          help="Geben Sie an, zu welchem Preis der Freelancer Ihnen die Maschine abkaufen muss."
        />
        <FormKit
          name="isSpecialOffer"
          type="checkbox"
          label="Als Sonderangebot markieren"
        />
        <FormKit
          name="note"
          type="text"
          label="Notiz an den Freelancer"
        />
      </FormKit>
    </TheDataCard>
  </ThePopup>
</template>
