<script setup lang="ts">
import { eachDayOfInterval, isAfter, isBefore, isSameDay, min } from 'date-fns'
import type { ApiRentalPauseGetAll, RentalPauseUpsert } from '~/types'

const props = defineProps<{ payload: RentalPauseUpsert, relatedRentalPauses: ApiRentalPauseGetAll[], validPeriod: { start: Date, end: Date | null } }>()
defineEmits<{ (e: 'save', payload: RentalPauseUpsert): void }>()

const { cloned } = useCloned(props.payload)

const relatedPauseDates = computed(() => props.relatedRentalPauses.flatMap(rp => eachDayOfInterval({ start: rp.startDate, end: rp.endDate }).map(date => date.getTime())))
const nextRelatedStartDate = computed(() => min(props.relatedRentalPauses.map(rp => rp.startDate).filter(d => isAfter(d, cloned.value.startDate)) ?? []) ?? cloned.value.startDate)
const dateInsideValidPeriod = (date: number | Date) => !isBefore(date, props.validPeriod.start) && (props.validPeriod.end ? !isAfter(date, props.validPeriod.end) : true)

const isValidStartDate = ({ value }: { value: number | Date }) => !relatedPauseDates.value.some(d => isSameDay(d, value)) && dateInsideValidPeriod(value)
const isValidEndDate = ({ value }: { value: number | Date }) => !relatedPauseDates.value.some(d => isSameDay(d, value)) && dateInsideValidPeriod(value) && !isAfter(value, nextRelatedStartDate.value) && !isBefore(value, cloned.value.startDate)
const isThereValidStartDate = computed(() => eachDayOfInterval({ start: props.validPeriod.start, end: props.validPeriod.end ?? props.validPeriod.start }).some(d => isValidStartDate({ value: d })))
</script>

<template>
  <FormKit v-model="cloned" type="form" :submit-label="$t('button.save')" @submit="$emit('save', cloned)">
    <n-alert v-if="!isThereValidStartDate" :show-icon="false" type="warning" class="mb-2">
      {{ $t('offer.rentalPause.noValidStartDate') }}
    </n-alert>
    <div class="flex space-x-2">
      <FormKitDateNaiveUi
        name="startDate"
        :label="$t('offer.rentalPause.startDate.label')"
        :is-date-disabled="(date: number) => !isValidStartDate({ value: date })"
        :validation-rules="{ isValidStartDate }"
        validation-visibility="live"
        validation="isValidStartDate"
      />
      <FormKitDateNaiveUi
        name="endDate"
        :label="$t('offer.rentalPause.endDate.label')"
        :is-date-disabled="(date: number) => !isValidEndDate({ value: date })"
        :validation-rules="{ isValidEndDate }"
        validation-visibility="live"
        validation="isValidEndDate"
      />
    </div>
  </FormKit>
</template>
