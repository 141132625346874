/**
 * SQLite doesn't have case sensitive search, but postgreSQL which we use in production
 * uses case sensitive search for default.
 * We are turning this off by adding { mode: 'insensitive' } into search object
 */
export default () => {
  if (isProduction) {
    return { mode: 'insensitive' }
  }
  return {}
}
