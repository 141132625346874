<script setup lang="ts">
withDefaults(defineProps<{ show: boolean, title?: string, width?: string, isLoading?: boolean }>(), {
  title: undefined,
  width: '700px',
  isLoading: false,
})

const emit = defineEmits<{ (e: 'close'): void }>()
</script>

<template>
  <n-modal
    :mask-closable="false"
    preset="card"
    :show="show"
    :title="title"
    :style="`width: ${width}`"
    @esc="emit('close')"
    @close="emit('close')"
  >
    <div v-if="isLoading">
      <n-skeleton />
    </div>
    <slot v-else />
  </n-modal>
</template>
