<script setup lang="ts">
import type { ApiMachineryGetById } from '~/types'

export interface MachineryAccessoryInspectionDatePayload {
  id: string
  technicalInspectionDate?: Date | null
  securityInspectionDate?: Date | null
  specialPermissionDate?: Date | null
}

const props = withDefaults(defineProps<{
  payload: ApiMachineryGetById | MachineryAccessoryInspectionDatePayload
  isMachineryAccessory?: boolean
}>(), {
  isMachineryAccessory: false,
})
const emit = defineEmits<{
  (e: 'saveMachinery', data: ApiMachineryGetById): void
  (e: 'saveMachineryAccessory', data: MachineryAccessoryInspectionDatePayload): void
}>()

const { useremail } = useAuthorization()

const hasSpecialPermission = ref(!!props.payload.specialPermissionDate)

function submitForm(data: ApiMachineryGetById | MachineryAccessoryInspectionDatePayload) {
  // Machinery accessory update uses specific updateInspectionDate router that receives specific inspection date fields,
  // while machinery update uses the general update router that receives all fields.
  // Both require different types, which is why the data is asserted to the respective parameter types.

  if (props.isMachineryAccessory) {
    // Metadata for machinery accessory inspection date is updated directly in the router

    emit('saveMachineryAccessory', data as MachineryAccessoryInspectionDatePayload)
  } else {
    const machineryData = data as ApiMachineryGetById

    // As data is updated using the general update router, some data must be manually set from the client
    machineryData.inspectionDateUpdatedAt = new Date()
    machineryData.inspectionDateUpdatedByEmail = useremail.value

    if (!hasSpecialPermission.value) {
      machineryData.specialPermissionDate = null
    }

    emit('saveMachinery', machineryData)
  }
}
</script>

<template>
  <FormKit
    type="form"
    :value="payload"
    submit-label="Speichern"
    @submit="submitForm"
  >
    <FormKitDate
      name="technicalInspectionDate"
      label="Nächste TÜV Prüfung fällig am"
    />

    <FormKitDate
      name="securityInspectionDate"
      label="Nächste Sicherheitsprüfung fällig am"
    />

    <FormKitDate
      v-if="!isMachineryAccessory"
      name="speedometerInspectionDate"
      label="Nächste Tacho Prüfung fällig am"
    />

    <n-checkbox v-model:checked="hasSpecialPermission" class="mb-2">
      Hat Sondergenehmigung
    </n-checkbox>
    <FormKitDate
      v-if="hasSpecialPermission"
      name="specialPermissionDate"
      label="Nächste Sondergenehmigung fällig am"
    />
  </FormKit>
</template>
