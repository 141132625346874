import { set } from 'jsonpointer'
import { refDebounced } from '@vueuse/core'
import { useRouteQuery } from '@vueuse/router'
import type { FulltextSearchFieldFilter } from './useTableColumnConfigs'

export default (queryConfig: { filters: FulltextSearchFieldFilter[], queryId?: string }, debounceWhereMilliseconds = 200, minimumSearchTermLength = 1) => {
  const { filters: jsonPointerFieldFiltersForFulltextSearch, queryId } = queryConfig
  const fulltextSearchValue = queryId ? useRouteQuery<string>(`q-${queryId}`, '') : ref<string>('')

  const fulltextSearchValueDebounced = refDebounced(fulltextSearchValue, debounceWhereMilliseconds)
  const where = computed(() => {
    const where_: { [k: string]: any } = {}

    if (fulltextSearchValueDebounced.value.length >= minimumSearchTermLength) {
      where_.OR = jsonPointerFieldFiltersForFulltextSearch.map((fieldFilter) => {
        const filter = {}

        if (typeof fieldFilter === 'string') {
          // By default we allow just a string to signify a LIKE-search on the field
          set(filter, fieldFilter, { contains: fulltextSearchValueDebounced.value, ...postgreSQLCaseInsensitiveSearch() })
        } else {
          // We also allow the developer to implement their own filter-making, e.g., to exactly match numbers
          const filterValue = fieldFilter.makeFilterValue(fulltextSearchValueDebounced.value)
          if (filterValue === undefined) {
            return undefined
          }

          set(filter, fieldFilter.field, filterValue)
        }
        return filter
      }).filter(filter => filter !== undefined)
    }

    return where_
  })

  return {
    fulltextSearchValue,
    fulltextSearchValueDebounced: readonly(fulltextSearchValueDebounced),
    where,
  }
}
