<script setup lang="ts">
import { FormKitMessages } from '@formkit/vue'
import { createLocalStoragePlugin } from '@formkit/addons'
import type { LocalStorageOptions } from '@formkit/addons'
import { machineryInsuranceDeductible, machineryPaymentConditions, machineryPurchasing, machineryRubrics } from '~/server/schemas'
import type { ApiCustomerGetAll, ApiMachineryDriveGetAll, ApiMachineryTypeGetAll, MachineryCategories, MachineryDrives, UpdateOrCreateMachinery } from '~/types'
import { machineryDrivesToGerman } from '~/translations'

const props = defineProps<{
  payload: UpdateOrCreateMachinery
  machineryTypes: ApiMachineryTypeGetAll[]
  createdTypeId: string | undefined
  machineryDrives: ApiMachineryDriveGetAll[]
  localStorageKey: LocalStorageOptions
  customers?: ApiCustomerGetAll[]
  onSave: (payload: UpdateOrCreateMachinery) => Promise<unknown>
}>()
const emit = defineEmits<{ (e: 'openMachineryTypePopup', category: MachineryCategories): void }>()
const { cloned } = useCloned(props.payload)
const { machineryFieldsByMachineType: showMachineryFieldsByMachineType } = useFormOptionalConditions()

const category = computed(() => props.payload.data.category)
const isForeignMachinery = computed(() => props.payload.data.isForeignMachinery)

watch(() => props.createdTypeId, (value) => {
  if (!value) {
    return
  }
  nextTick(() => {
    cloned.value.data.typeId = value
  })
})

const currentPaymentCondition = ref<string>('')
const specialPaymentCondition = ref<string>('')
onMounted(() => {
  currentPaymentCondition.value = cloned.value.data.paymentCondition
  if (!machineryPaymentConditions.safeParse(currentPaymentCondition.value).success) {
    currentPaymentCondition.value = 'Sonstiges'
    specialPaymentCondition.value = cloned.value.data.paymentCondition
  }
})

function updateCurrentPaymentCondition(selectedOption: string) {
  currentPaymentCondition.value = selectedOption
  if (selectedOption === 'Sonstiges') {
    cloned.value.data.paymentCondition = ''
  }
}

const machineryTypeOptions = computed(() => props.machineryTypes.filter(type => type.category === props.payload.data.category).map(type => ({
  label: `${type.name}`,
  value: type.id,
})))

const machineryPaymentConditionsOptions = computed(() => machineryPaymentConditions.options.map(option => ({
  label: option,
  value: option,
})))

const machineryInsuranceDeductibleOptions = machineryInsuranceDeductible.options.map(option => ({
  label: `${option} EUR`,
  value: option,
}))

const machineryDrivesOptions = computed(() => props.machineryDrives.map(drive => ({
  label: machineryDrivesToGerman[drive.name as MachineryDrives],
  value: drive.id,
})))

const customerOptions = computed(() => props.customers?.map(customer => ({
  label: customer.name,
  value: customer.id,
})) ?? [])

const machineryType = ref()

function save() {
  if (currentPaymentCondition.value === 'Sonstiges') {
    cloned.value.data.paymentCondition = specialPaymentCondition.value
  } else {
    cloned.value.data.paymentCondition = currentPaymentCondition.value
  }

  if (!cloned.value.data.driveId) {
    const drive = props.machineryDrives.find(drive => drive.name === 'noDrive')

    // If it's empty, it will just throw an error like it normally would.
    cloned.value.data.driveId = drive?.id ?? ''
  }

  return props.onSave(cloned.value)
}

const formkitPlugins = useFormkitPlugins()
</script>

<template>
  <FormKit
    v-slot="{ value }"
    v-model="cloned.data"
    name="MachineryCreationCreateOrEditForm"
    type="form"
    :plugins="[formkitPlugins, createLocalStoragePlugin(localStorageKey)]"
    use-local-storage
    submit-label="Gerät speichern"
    @submit="save"
  >
    <TheDevOnlyNiceJson :form-value="value" />
    <FormKit
      v-if="cloned.mode === 'create' && !isForeignMachinery"
      id="id"
      type="number"
      validation="min:0|max:5999"
      label="Id"
      placeholder="5999"
      help="Verwenden Sie dieses Feld wenn Sie eine ID aus dem Alt-System eingeben wollen"
    />
    <FormKitMultiSelect
      v-if="isForeignMachinery"
      id="foreignMachineryCustomerId"
      :is-multi-select-allowed="false"
      :multi-select-options="customerOptions"
      label="Kunde"
      validation="required"
    />
    <div class="flex flex-col md:flex-row md:space-x-2">
      <FormKit
        id="deliveryCompanyName"
        type="text"
        validation="required"
        label="Lieferant"
        placeholder="Spedition Muster GmbH"
      />
      <FormKit id="producerCompanyName" type="text" validation="required" label="Hersteller" placeholder="Toyota" />
    </div>
    <div class="flex flex-col md:flex-row gap-2">
      <div class="w-full">
        <FormKit type="group">
          <label>
            <span class="font-bold">Typ *</span>
            <n-select
              :value="cloned.data.typeId ? cloned.data.typeId : null"
              :options="machineryTypeOptions"
              filterable
              size="large"
              placeholder="H16/900-02, 2021"
              @update:value="value => { cloned.data.typeId = value }"
            >
              <template #action>
                <n-button style="width: 100%" @click="emit('openMachineryTypePopup', cloned.data.category)">
                  Neuen Gerätetyp anlegen
                </n-button>
              </template>
            </n-select>
            <FormKitMessages :node="machineryType?.node" />
          </label>
          <FormKit
            ref="machineryType"
            v-model="cloned.data.typeId"
            label="Typ"
            name="typeId"
            type="hidden"
            validation="required"
          />
        </FormKit>
      </div>
      <FormKit
        id="machineryRubric"
        type="select"
        :options="machineryRubrics.options"
        validation="required"
        label="Rubrik"
      />
    </div>
    <div class="flex space-x-2">
      <FormKit id="serialNumber" type="text" label="Seriennummer" placeholder="12313KFR" />
      <FormKit id="yearBuilt" type="number" validation="required" label="Baujahr" placeholder="2024" />
    </div>
    <div class="flex space-x-2">
      <FormKit
        id="driveId"
        :value="cloned.data.driveId ? cloned.data.driveId : undefined"
        type="select"
        :options="machineryDrivesOptions"
        validation="required"
        label="Antrieb"
        placeholder="Elektro"
        class="WidthHalfMinusGap"
      />
      <FormKit
        v-if="showMachineryFieldsByMachineType.liftingHeightInMillimeters.includes(category)"
        id="liftingHeightInMillimeters"
        type="number"
        step="0.01"
        label="Hubhöhe (mm)"
        placeholder="5500"
      />
    </div>

    <FormKit
      v-if="showMachineryFieldsByMachineType.licensePlateNumber.includes(category)"
      id="licensePlateNumber"
      type="text"
      label="Kennzeichen"
      placeholder="H OS 1234"
      class="WidthHalfMinusGap"
    />

    <div class="flex space-x-2">
      <div v-if="showMachineryFieldsByMachineType.liftingWeight.includes(category)" class="w-full md:WidthHalfMinusGap">
        <FormKit
          id="liftingWeight"
          type="number"
          step="0.01"
          validation="required"
          label="Tragkraft (kg)"
          placeholder="1800"
        />
      </div>
      <div v-if="showMachineryFieldsByMachineType.forkLengthInMillimeters.includes(category)" class="w-full md:WidthHalfMinusGap">
        <FormKit id="forkLengthInMillimeters" type="number" step="0.01" label="Gabellänge (mm)" placeholder="3500" />
      </div>
    </div>

    <div class="flex space-x-2">
      <FormKit v-if="payload.mode === 'create'" id="storageLocation" type="text" validation="required" label="Lager" placeholder="Crailsheim" />

      <FormKit
        id="pricePurchaseEuros"
        type="number"
        step="0.01"
        validation="required"
        min="0"
        label="EK-Preise (EUR)"
        placeholder="10000"
      />
    </div>

    <div class="flex space-x-2">
      <div v-if="showMachineryFieldsByMachineType.workHeightInMillimeters.includes(category)" class="w-full md:WidthHalfMinusGap">
        <FormKit
          id="workHeightInMillimeters"
          type="number"
          step="0.01"
          label="Arbeitshöhe (mm)"
          placeholder="4500"
        />
      </div>
      <div class="w-full md:WidthHalfMinusGap">
        <FormKitDate id="deliveryDate" label="Lieferdatum" />
      </div>
    </div>

    <div class="flex gap-x-2">
      <FormKitMultiSelect
        v-model="currentPaymentCondition"
        :multi-select-options="machineryPaymentConditionsOptions"
        :is-multi-select-allowed="false"
        validation="required"
        label="Zahlungsbedingung"
        :update="updateCurrentPaymentCondition"
      />
      <FormKit
        v-if="currentPaymentCondition === 'Sonstiges'"
        v-model="specialPaymentCondition"
        type="text"
        validation="required"
        label="Sonstige Zahlungsbedingung"
      />
    </div>

    <div class="flex space-x-2 items-end md:items-start">
      <FormKit
        id="purchasing"
        type="select"
        :options="machineryPurchasing.options"
        label="Beschaffung"
        :placeholder="machineryPurchasing.options[0]"
      />
      <FormKit id="additionalDeliveryCost" type="number" step="0.01" label="Zusatzkosten durch Abholung oder Lieferung" placeholder="500">
        <template #suffix>
          <span class="pr-6">€</span>
        </template>
      </FormKit>
    </div>
    <div class="flex space-x-2">
      <FormKit
        id="insuranceDeductible"
        type="select"
        :options="machineryInsuranceDeductibleOptions"
        label="Maschinenbruchversicherung - Selbstbehalt"
        placeholder="Bitte wählen"
      />
    </div>
    <FormKitFileDropzone
      id="documentFiles"
      name="documentFiles"
      label="Zusatzdokumente"
    />
    <FormKit id="comment" type="text" label="Notiz" placeholder="Wird mit 2x Seilwinde ausgeliefert" />
  </FormKit>
</template>

<style scoped>
  .WidthHalfMinusGap {
    width: calc(50% - 0.5rem)
  }

  :deep(.formkit-actions) button {
    @apply w-full md:w-auto justify-center md:justify-start;
  }
</style>
