<script setup lang="ts">
import type { FormKitOptionsItem } from '@formkit/inputs'
import type { PartialStoragePositionUpsert } from '~/server/trpc/routers/externalStorage/partialStoragePosition'

const props = defineProps<{
  payload?: PartialStoragePositionUpsert
  storagePositionId: string
  projectId: string
  maxQuantity: number
  washingEnabled: boolean
  packagingLotSize?: number | null
  carrierNameOptions: FormKitOptionsItem[]
  carrierLicensePlateOptions: FormKitOptionsItem[]
}>()
const emit = defineEmits<{
  (e: 'save', payload: PartialStoragePositionUpsert): void
}>()

const isProjectAddressRequired = ref(false)
const projectAddressId = ref(props.payload?.projectAddressId)
watch(() => props.payload?.projectAddressId, (newValue) => {
  projectAddressId.value = newValue
})

const quantity = ref(props.maxQuantity)

const calculatedLotSize = computed(() => {
  if (!quantity.value || !props.packagingLotSize) {
    return 0
  }

  const lotSize = calculateLotSize(quantity.value, props.packagingLotSize)
  return formatNumberToString(lotSize, 'de', { maximumFractionDigits: 2 })
})

function getInitialIsDeliveryDoneByHanselmann() {
  // If payload is undefined, the partial position is yet to be created.
  // Hence, the default setting: delivery is done by Hanselmann.
  if (!props.payload) {
    return true
  }

  // If the customer takes over the task, the delivery is not done by Hanselmann.
  return !props.payload.doesCustomerTakeOverTask
}
const isDeliveryDoneByHanselmann = ref(getInitialIsDeliveryDoneByHanselmann())

function save(data: PartialStoragePositionUpsert) {
  emit('save', {
    ...data,
    doesCustomerTakeOverTask: !isDeliveryDoneByHanselmann.value,
  })
}
</script>

<template>
  <FormKit
    :value="payload"
    type="form"
    @submit="save"
  >
    <FormKit
      v-if="washingEnabled"
      name="needsToBeWashedOnRetrieval"
      type="checkbox"
      label="Muss gewaschen werden"
    />

    <FormKit
      v-model="isDeliveryDoneByHanselmann"
      label="Lieferung soll von Hanselmann durchgeführt werden"
      type="checkbox"
    />

    <template v-if="!isDeliveryDoneByHanselmann">
      <FormKitAutocomplete
        name="carrierName"
        label="Spedition"
        :is-option-creation-allowed="true"
        :options="carrierNameOptions"
        validation="required"
      />

      <FormKitAutocomplete
        name="carrierLicensePlate"
        label="Kennzeichen"
        :is-option-creation-allowed="true"
        :options="carrierLicensePlateOptions"
        validation="required"
      />
    </template>

    <FormKitDateTime
      name="deliveryAt"
      label="Lieferdatum"
      validation="required"
    />

    <!-- TODO Awaiting discussion with Hanselmann -->
    <!-- <FormKit
      name="deliveryTimeSlot"
      type="select"
      label="Lieferslots"
    /> -->

    <FormKit
      v-model="quantity"
      name="quantity"
      type="number"
      label="Menge"
      number
      :validation="[
        ['required'],
        ['min', 1],
        ['max', maxQuantity],
      ]"
    />
    <p v-if="packagingLotSize" class="mb-5 text-orange-500">
      VPE Menge entspricht {{ calculatedLotSize }} Stück
    </p>

    <FormKit
      name="productionOrderNumber"
      type="text"
      label="(optional) Fertigungsauftragsnummer"
    />

    <FormKit
      name="comment"
      type="textarea"
      label="Kommentar (z.b. Info zu Kontaktperson)"
    />

    <FormKitFileDropzone
      name="documents"
      label="(optional) Palettenbegleitschein hinzufügen (max. 1 PDF)"
      wrap-path-in-object
      accept="application/pdf"
      :validation="[['max', 1]]"
      :validation-messages="{
        max: 'Bitte nicht mehr als ein PDF-Dokument hochladen',
      }"
    />

    <div class="mb-4">
      <ExternalStorageAdministrationStorageProjectAddressInput
        :project-id="projectId"
        :selected-project-address-id="projectAddressId"
        @require-alternative-address="value => isProjectAddressRequired = value"
        @update:value="value => projectAddressId = value"
      />
    </div>

    <!-- Hidden fields -->
    <FormKit
      name="storagePositionId"
      type="hidden"
      :value="storagePositionId"
    />

    <FormKit
      v-model="projectAddressId"
      name="projectAddressId"
      type="hidden"
      :validation="isProjectAddressRequired ? 'required' : ''"
    />
  </FormKit>
</template>
