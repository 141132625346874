import revive_payload_client_QnuDKul4yN from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_encoding@0.1.13_eslint@8.57.0_ioredis@_cz2ydkoty3snnq3xowinleozzi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3vz1M47Wcw from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_encoding@0.1.13_eslint@8.57.0_ioredis@_cz2ydkoty3snnq3xowinleozzi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_kL6mo3myyh from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_encoding@0.1.13_eslint@8.57.0_ioredis@_cz2ydkoty3snnq3xowinleozzi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import payload_client_QgB3rLQU4o from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_encoding@0.1.13_eslint@8.57.0_ioredis@_cz2ydkoty3snnq3xowinleozzi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JG42L7ofZ6 from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_encoding@0.1.13_eslint@8.57.0_ioredis@_cz2ydkoty3snnq3xowinleozzi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Mq5Rxy27Hw from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_encoding@0.1.13_eslint@8.57.0_ioredis@_cz2ydkoty3snnq3xowinleozzi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_wWV6RR3oxo from "/app/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@20.14.11_encoding@0.1.13_eslint@8.57.0_ioredis@_cz2ydkoty3snnq3xowinleozzi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_NWjxbm2KKj from "/app/node_modules/.pnpm/@nuxtjs+plausible@1.0.0_magicast@0.3.4_rollup@4.18.1/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import plugin_exmbPOTmB8 from "/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.4.5_magicast@0.3.4_rollup@4.18.1_webpack@5.92.0/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import i18n_02OVdVMUAH from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.18.1_vue@3.4.32_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/app/.nuxt/formkitPlugin.mjs";
import plugin_lUFac982k7 from "/app/node_modules/.pnpm/@nuxt+icon@1.4.5_magicast@0.3.4_rollup@4.18.1_vite@5.3.4_@types+node@20.14.11_terser@5.31.6___7sjn3d6zcodxkkwsdi5536bom4/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import colorMode_WZYXSFG0OC from "/app/node_modules/.pnpm/@bg-dev+nuxt-naiveui@1.14.0_magicast@0.3.4_rollup@4.18.1_vue@3.4.32_typescript@5.5.4_/node_modules/@bg-dev/nuxt-naiveui/dist/runtime/plugins/colorMode.mjs";
import plugin_G8Z0SewV0D from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.2_encoding@0.1.13_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@babel+_z7f3tn6w46hqro3vakrqddhwja/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import chunk_reload_client_BG2S3BHLQY from "/app/plugins/chunk-reload.client.ts";
import signaturePad_StdQn7RF4b from "/app/plugins/signaturePad.ts";
import trpcClient_M8UiGTyzsx from "/app/plugins/trpcClient.ts";
import vue_query_wrmMkNpEpe from "/app/plugins/vue-query.ts";
import plugin_auto_pageviews_client_GV1CP3HGel from "/app/node_modules/.pnpm/@nuxtjs+plausible@1.0.0_magicast@0.3.4_rollup@4.18.1/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.mjs";
export default [
  revive_payload_client_QnuDKul4yN,
  unhead_3vz1M47Wcw,
  router_kL6mo3myyh,
  sentry_client_shVUlIjFLk,
  payload_client_QgB3rLQU4o,
  navigation_repaint_client_JG42L7ofZ6,
  check_outdated_build_client_Mq5Rxy27Hw,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wWV6RR3oxo,
  plugin_client_NWjxbm2KKj,
  plugin_exmbPOTmB8,
  i18n_02OVdVMUAH,
  formkitPlugin_pZqjah0RUG,
  plugin_lUFac982k7,
  colorMode_WZYXSFG0OC,
  plugin_G8Z0SewV0D,
  chunk_reload_client_BG2S3BHLQY,
  signaturePad_StdQn7RF4b,
  trpcClient_M8UiGTyzsx,
  vue_query_wrmMkNpEpe,
  plugin_auto_pageviews_client_GV1CP3HGel
]