<script setup lang="ts">
/**
 * TODO: remove this file when we don't need it anymore
 * @temporary
 * This is for allow user to edit the termination day of the offers
 * that are terminated before the partial termination implemented
 * see https://github.com/sidestream-tech/hanselmann-os/issues/1526 for more information
 */
import type { ApiInputOfferRevertEndedObligation } from '~/types'

const { openOfferRevertTerminationPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()
const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()
const { offer: queryOffer } = useQuery()
const { data: offer, isLoading: isLoadingOffer } = queryOffer.byId(computed(() => popupData.value?.id))

const revertTermination = useMutation({
  mutationFn: $trpc.offer.revertEndedOfferObligation.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Die Rückgängigmachung kann nicht durchgeführt werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['logisticsTask'] }),
      queryClient.invalidateQueries({ queryKey: ['offer'] }),
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
    ])
    closePopup()
    notification.success({ title: 'Die Rückgängigmachung wurde erfolgreich durchgeführt', duration: 4500 })
  },
})
const isTerminated = computed(() => offer.value?.logisticsTasks.some(task => task.status === 'terminated'))

const revertTerminationPayload = computed((): ApiInputOfferRevertEndedObligation | undefined => {
  if (!popupData.value || !offer.value || isLoadingOffer.value) {
    return undefined
  }
  return {
    id: offer.value.id,
  }
})
</script>

<template>
  <ThePopup v-if="revertTerminationPayload && offer && isTerminated" :show="Boolean(revertTerminationPayload)" :title="`Abmeldung von dem Auftrag ${revertTerminationPayload.id ?? 'N/A'} rückgängig machen`" @close="closePopup">
    <LogisticsTerminationRevertFormForNotPartialTerminatedOffer :payload="revertTerminationPayload" @save="revertTermination.mutate" />
  </ThePopup>
</template>
