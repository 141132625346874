<script setup lang="ts">
import { machineryAccessoryCategoryToGerman } from '~/translations'
import type { MachineryAccessoryCategory, OfferStatus, ShoppingCartPosition } from '~/types'
import type { ShoppingTab } from '~/components/Calendar/Page.vue'

const props = defineProps<{
  machineryId: string | undefined
  showAttachbleTab: boolean
  offerStartDay: Date
  offerEndDay?: Date
  selectedTab: ShoppingTab
  selectedMachineryAccessoryCategory: MachineryAccessoryCategory | null
  isCategorySelectDisabled?: boolean
  isForkEditMode: boolean
  isAccessoryOnlyOffer: boolean
  offer: { status: OfferStatus, id?: string }
  disabledAccessoryIds: string[]
  compatibleAccessoryId?: string
}>()

const emits = defineEmits<{
  (e: 'selectTab', value: ShoppingTab): void
  (e: 'selectMachineryAccessoryCategory', value: MachineryAccessoryCategory | null): void
  (e: 'updateIsForkEditMode', value: boolean): void
  (e: 'openAccessoryIdSearchPopup'): void
}>()

const cartPositions = defineModel<ShoppingCartPosition[]>({ default: () => [] })

const { shopping, machineryAccessory } = useQuery()

watch(() => props.showAttachbleTab, (newShow, oldShow) => {
  if (newShow !== oldShow && newShow) {
    emits('selectTab', 'attachable')
  }
})
watch(() => props.selectedTab, (newTab, oldTab) => {
  if (newTab === oldTab) {
    return
  }

  if (newTab === 'attachable') {
    emits('selectMachineryAccessoryCategory', availableMachineryAccessoryOptionsAttachable.value[0]?.value ?? null)
  } else if (newTab === 'compatible') {
    emits('selectMachineryAccessoryCategory', availableMachineryAccessoryOptions.value[0]?.value ?? null)
  } else {
    emits('selectMachineryAccessoryCategory', allMachineryAccessoryOptions.value[0]?.value ?? null)
  }
})

const { data: compatibleAccessoryIdsData } = machineryAccessory.compatibility.getCompatibleAccessoryIds(ref(props.compatibleAccessoryId))
const compatibleAccessoryIds = computed(() => compatibleAccessoryIdsData.value ?? [])

const { data: availabilityCounts, isLoading: isLoadingCounts } = shopping.accessoryAvailabilitiesByMachineryId(
  computed(() => props.machineryId),
  ref(null),
  computed(() => props.offerStartDay),
  computed(() => props.offerEndDay),
  true,
  computed(() => props.offer.status),
  props.compatibleAccessoryId ? compatibleAccessoryIds : undefined,
)

const { data: attachedAccessories } = shopping.getAttachedMachineryAccessories(computed(() => props.machineryId))

const allMachineryAccessoryOptions = computed(() => {
  if (!availabilityCounts.value || props.selectedTab === 'attachable') {
    return []
  }

  const selectedCompatability = props.selectedTab

  return Object.entries(availabilityCounts.value.resultsByAccessoryCategory)
    .map(([key, counts]) => {
      const label = `${machineryAccessoryCategoryToGerman[key as MachineryAccessoryCategory]} (${counts[selectedCompatability]} verfügbar)`

      return { label, value: key as MachineryAccessoryCategory, counts }
    })
    .sort((optionA, optionB) => optionB.counts[selectedCompatability] - optionA.counts[selectedCompatability] || optionA.label.localeCompare(optionB.label))
})

const availableMachineryAccessoryOptions = computed(() => {
  if (!availabilityCounts.value || props.selectedTab === 'attachable') {
    return []
  }

  const selectedCompatability = props.selectedTab

  return Object.entries(availabilityCounts.value.resultsByAccessoryCategory)
    .map(([key, counts]) => {
      const label = `${machineryAccessoryCategoryToGerman[key as MachineryAccessoryCategory]} (${counts[selectedCompatability]} verfügbar)`

      return { label, value: key as MachineryAccessoryCategory, counts }
    })
    .filter(option => option.counts.isAttachable === false)
    .sort((optionA, optionB) => optionB.counts[selectedCompatability] - optionA.counts[selectedCompatability] || optionA.label.localeCompare(optionB.label))
})

const availableMachineryAccessoryOptionsAttachable = computed(() => {
  if (!availabilityCounts.value) {
    return []
  }

  return Object.entries(availabilityCounts.value.resultsByAccessoryCategory)
    .map(([key, counts]) => {
      const label = `${machineryAccessoryCategoryToGerman[key as MachineryAccessoryCategory]} (${counts.compatible} verfügbar)`

      return { label, value: key as MachineryAccessoryCategory, counts }
    })
    .filter(option => option.counts.isAttachable === true)
    .sort((optionA, optionB) => optionB.counts.compatible - optionA.counts.compatible || optionA.label.localeCompare(optionB.label))
})

watch(availableMachineryAccessoryOptions, (newOptions) => {
  if (newOptions.length === 0 || props.selectedMachineryAccessoryCategory !== null) {
    return
  }

  if (props.selectedTab === 'attachable') {
    emits('selectMachineryAccessoryCategory', availableMachineryAccessoryOptionsAttachable.value[0]?.value ?? null)
  } else {
    emits('selectMachineryAccessoryCategory', availableMachineryAccessoryOptions.value[0]?.value ?? null)
  }
  emits('selectMachineryAccessoryCategory', newOptions[0].value)
})

const totalCounts = computed(() => {
  const totals = {
    attached: 0,
    compatibleAndAttachable: 0,
    compatible: 0,
    incompatible: 0,
  }
  if (!availabilityCounts.value) {
    return totals
  }

  totals.attached = availabilityCounts.value.attached

  for (const { compatible, incompatible, isAttachable } of Object.values(availabilityCounts.value.resultsByAccessoryCategory)) {
    if (isAttachable) {
      totals.compatibleAndAttachable += compatible
    } else {
      totals.compatible += compatible
    }

    totals.incompatible += incompatible
  }

  return totals
})

const comapatabilities = [{
  value: 'compatible',
  label: 'Kompatibel',
}, {
  value: 'incompatible',
  label: 'Weitere Lagertool Geräte',
}] as const

const showAccessoryPanel = computed(() => {
  if (!availabilityCounts.value) {
    return false
  }
  const isAccessoryAvailable = Object.keys(availabilityCounts.value).length > 0

  return isAccessoryAvailable && (props.machineryId || props.isAccessoryOnlyOffer)
})

const { openPositionsDetailsPopup } = useGlobalOpeners()
</script>

<template>
  <TheDataCard>
    <div class="flex justify-between">
      <h3 class="text-xl mb-2">
        Anbau-Geräte konfigurieren
      </h3>
      <n-button v-if="showAccessoryPanel && !compatibleAccessoryId" class="mb-4" :disabled="isForkEditMode" @click="emits('openAccessoryIdSearchPopup')">
        Nach ID suchen
      </n-button>
    </div>

    <div v-if="isLoadingCounts" class="w-full h-full flex justify-start items-center">
      <n-spin size="large" />
      <span class="ml-3">Zum Gerät passende Anbau-Geräte werden geladen...</span>
    </div>

    <div v-else-if="showAccessoryPanel">
      <n-tabs :value="selectedTab" type="segment" animated @update:value="(val) => emits('selectTab', val)">
        <n-tab-pane v-if="props.showAttachbleTab" key="attached" name="attachable" :tab="`Angebracht (${totalCounts.compatibleAndAttachable} verfügbar, ${totalCounts.attached} angebracht)`">
          <div class="my-4">
            <p>Folgende Anbau-Geräte sind bereits fest mit dem Gerät {{ machineryId }} verbunden:</p>
            <p v-for="(accessory, index) of attachedAccessories" :key="accessory.id">
              {{ index + 1 }}. {{ machineryAccessoryCategoryToGerman[accessory.category as MachineryAccessoryCategory] }} <span class="underline text-blue-400 hover:cursor-pointer" @click="openPositionsDetailsPopup.open({ type: 'machineryAccessory', id: accessory.id })">({{ accessory.id }})</span>
            </p>
          </div>
          <n-select :value="selectedMachineryAccessoryCategory" filterable :options="availableMachineryAccessoryOptionsAttachable" :disabled="isForkEditMode" @update:value="(val) => emits('selectMachineryAccessoryCategory', val)" />
          <ShoppingAccessoryDetailsByCategory
            v-if="selectedMachineryAccessoryCategory"
            v-model="cartPositions"
            :selected-tab="selectedTab"
            compatability="compatible"
            :machinery-id="machineryId"
            :selected-machinery-accessory-category="selectedMachineryAccessoryCategory"
            :show-selection="selectedMachineryAccessoryCategory === 'fork'"
            :offer-start-day="props.offerStartDay"
            :offer-end-day="props.offerEndDay"
            :attached-fork-id="attachedAccessories?.find(accessory => accessory.category === 'fork')?.id"
            select-type="single"
            :is-fork-edit-mode="isForkEditMode"
            :offer="offer"
            :disabled-accessory-ids="disabledAccessoryIds"
            :compatible-accessory-id="compatibleAccessoryId"
            :current-compatible-accessory-ids="compatibleAccessoryIds"
            @update-is-fork-edit-mode="emits('updateIsForkEditMode', $event)"
          />
        </n-tab-pane>
        <n-tab-pane
          v-for="compatability in comapatabilities"
          :key="compatability.value"
          :name="compatability.value"
          :tab="`${compatability.label} (${totalCounts[compatability.value]} verfügbar)`"
          :disabled="isForkEditMode || (compatability.value === 'compatible' && isAccessoryOnlyOffer)"
        >
          <n-select v-if="!isCategorySelectDisabled" :value="selectedMachineryAccessoryCategory" filterable :options="compatability.value === 'compatible' ? availableMachineryAccessoryOptions : allMachineryAccessoryOptions" :disabled="isForkEditMode" @update:value="(val) => emits('selectMachineryAccessoryCategory', val)" />
          <ShoppingAccessoryDetailsByCategory
            v-if="selectedMachineryAccessoryCategory"
            v-model="cartPositions"
            :selected-tab="selectedTab"
            :compatability="compatability.value"
            :machinery-id="machineryId"
            :selected-machinery-accessory-category="selectedMachineryAccessoryCategory"
            :show-selection="true"
            :offer-start-day="props.offerStartDay"
            :offer-end-day="props.offerEndDay"
            :offer="offer"
            :disabled-accessory-ids="disabledAccessoryIds"
            :compatible-accessory-id="compatibleAccessoryId"
            :current-compatible-accessory-ids="compatibleAccessoryIds"
            select-type="multi"
          />
        </n-tab-pane>
      </n-tabs>
    </div>
    <div v-else>
      Zur gewählten Maschine gibt es keine passenden Anbau-Geräte.
    </div>
  </TheDataCard>
</template>
