<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'

const { openPositionsDetailsPopup: { data: popupData, close: closePopup_ } } = useGlobalOpeners()

// This value is used in MachineryDetailsPagePrivate component to set menu value
const machineryDetailsView = useRouteQuery('view')

const typeToText: Readonly<Record<Exclude<typeof popupData.value, undefined>['type'], string>> = {
  machinery: 'Geräte',
  machineryAccessory: 'Lagertool',
  itemSet: 'Set',
} as const

const positionInGerman = computed(() => popupData.value ? typeToText[popupData.value.type] : '')

function closePopup() {
  machineryDetailsView.value = null
  closePopup_()
}
</script>

<template>
  <ThePopup
    v-if="popupData"
    :show="Boolean(popupData)"
    :title="`${positionInGerman}-Übersicht`"
    width="800px"
    @close="closePopup"
  >
    <MachineryDetailsPagePrivate
      v-if="popupData.type === 'machinery'"
      is-update-disabled
      :machinery-id="popupData.id"
    />
    <MachineryAccessoryDetailsPagePrivate
      v-else-if="popupData.type === 'machineryAccessory'"
      is-update-disabled
      :machinery-accessory-id="popupData.id"
    />
    <SetDetailsPrivate
      v-else
      is-update-disabled
      :set-id="popupData.id"
    />
  </ThePopup>
</template>
