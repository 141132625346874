<script setup lang="ts">
const props = defineProps<{ machineryAccessoryId?: string, fromItemSet: boolean }>()

const selectedDuration = props.fromItemSet ? useRouteQueryAsDateRange('machineryAccessoryForItemSetInvoicingDateRange') : useRouteQueryAsDateRange('machineryAccessoryInvoicingDateRange')

const { reporting } = useQuery()
const { data: machineryAccessoryRevenueChart, isLoading } = reporting.getChartRevenueMachineryAccessory(selectedDuration, props.fromItemSet, props.machineryAccessoryId)
</script>

<template>
  <ReportingCard header="Lagertool Umsatz" :is-loading="isLoading">
    <template #controls>
      <TheDurationPicker v-model="selectedDuration" class="mb-2" />
      <p class="mb-2">
        Der hier dargestellte Umsatz wird aus offenen und bezahlten Rechnungen berechnet, nachträglich erstellte Rechnungskorrekturen werden nicht berücksichtigt. Zur Berechnung werden ausschließlich die Lagertool-Positionen berücksichtigt. Die Berechnung basiert auf den jeweils von dem Lagertool abgerechneten Tagen.
      </p>
      <p class="mb-2 font-semibold">
        Gesamt-Lagertool-Umsatz im Zeitraum: {{ useDisplayNumberAsCurrency(machineryAccessoryRevenueChart?.additionalData?.totalRevenue ?? 0) }}
      </p>
      <p>Umsatz pro Tag:</p>
    </template>

    <ReportingChart v-if="machineryAccessoryRevenueChart" v-bind="machineryAccessoryRevenueChart" />
  </ReportingCard>
</template>
