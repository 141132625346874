<script setup lang="ts">
import type { ApiMachineryGetById, CalendarPageOfferCreationModeEmit } from '~/types'

const props = defineProps<{ machinery: ApiMachineryGetById, freelancerSaleCreationMode: CalendarPageOfferCreationModeEmit }>()

const { freelancerSales } = useQuery()
const { data } = freelancerSales.getReleaseConditionsForMachineryForFreelancer(props.machinery.id)
const { openOfferPage } = useGlobalOpeners()
</script>

<template>
  <n-alert v-if="data?.isSpecialOffer" type="warning">
    Bitte beachte, bei dieser Maschine handelt es sich um ein Sonderangebot!
  </n-alert>

  <n-alert v-if="data" type="info">
    <p>Dieses Gerät wurde Ihnen zum Verkauf freigegeben.</p>
    <p>- UVP: {{ machinery.priceRecommendedToSellFor ? useDisplayNumberAsCurrency(machinery.priceRecommendedToSellFor) : 'N/A' }}</p>
    <p>- Dein EK-Preis: {{ useDisplayNumberAsCurrency(data.priceFreelancerHasToPurchaseFor) }}</p>
    <p>- Hanselmann Online Preis: {{ machinery.priceHanselmannPubliclySellsFor ? useDisplayNumberAsCurrency(machinery.priceHanselmannPubliclySellsFor) : 'N/A' }}</p>
    <p v-if="data.note" class="my-2">
      {{ data.note }}
    </p>
    <p class="my-2">
      Sie können rechts oben Schnellangebote erstellen oder hier direkt ein vollständiges Angebot.
    </p>
    <FormKit
      type="button"
      label="Angebot erstellen"
      help="Erstellt ein vollwertiges Angebot für diese Maschine."
      @click="openOfferPage({ mode: 'create', type: 'sale', status: 'offer', offerCreationMode: freelancerSaleCreationMode, redirectUrl: '/freelancer-sales/sale/current/offer' })"
    />
  </n-alert>
</template>
