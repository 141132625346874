export default defineNuxtRouteMiddleware((to) => {
  if (to.path.startsWith('/auth')) {
    return
  }

  const { canAccessPage, role } = useAuthorization()

  // Redirect customer-users to the other landing page
  if (role.value === 'customer-user' && to.path === '/') {
    return navigateTo('/my-hos')
  }

  if (!canAccessPage(to.path)) {
    if (role.value === 'customer-user') {
      return navigateTo('/my-hos')
    }
    return navigateTo('/')
  }
})
