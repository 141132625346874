<script setup lang="ts">
import type { ApiMachineryGetById, ApiOfferGetById, OfferType } from '~/types'
import { offerTypesToGerman } from '~/translations'

const { openInternalCostPopup: { data: popupData, close: closePopup } } = useGlobalOpeners()

const machineryOverview: OneDataColumn<ApiMachineryGetById>[] = [
  {
    title: 'Geräte Nr.',
    key: 'id',
  },
  {
    title: 'Geräte Typ',
    key: 'type',
    render: ({ type }) => type.name,
  },
  {
    title: 'Hersteller',
    key: 'producerCompanyName',
  },
]

const offerOverview: OneDataColumn<ApiOfferGetById>[] = [
  {
    title: 'Auftragsnummer',
    key: 'id',
  },
  {
    title: 'Auftragsart',
    key: 'type',
    render: ({ type }) => offerTypesToGerman[type as OfferType],
  },
  {
    title: 'Kunde',
    key: 'customer',
    render: ({ customer }) => customer.name,

  },
]

const machineryId = computed(() => {
  if (popupData.value?.type === 'machinery') {
    return popupData.value.id
  }
  return undefined
})

const offerId = computed(() => {
  if (popupData.value?.type === 'offer') {
    return popupData.value.id
  }
  return undefined
})

const { machinery: machineryQuery, offer: offerQuery } = useQuery()

const { data: machinery } = machineryQuery.byId(machineryId)
const { data: offer } = offerQuery.byId(offerId)
</script>

<template>
  <ThePopup v-if="popupData" :show="Boolean(popupData)" :title="`Interne Kosten für ${popupData.type === 'machinery' ? 'Maschine' : 'Auftrag'} ${popupData.id}`" width="1100px" @close="closePopup">
    <TableOneDataColumn v-if="machinery" class="mb-2" :config="{ columns: machineryOverview, data: machinery }" />
    <TableOneDataColumn v-if="offer" class="mb-2" :config="{ columns: offerOverview, data: offer }" />
    <InternalCostPage :id="popupData.id" :type="popupData.type" />
  </ThePopup>
</template>
