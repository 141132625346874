<script setup lang="ts">
import type { ApiCommentGetAll } from '~/types'

const props = withDefaults(defineProps<{
  id: string
  type: CommentType
  isReadOnly?: boolean
  isClosable?: boolean
  hideListTitle?: boolean
  listTitleText?: string
  refreshQueries?: CommentType[]
  canDelete?: boolean
}>(), {
  isClosable: true,
  hideListTitle: false,
  listTitleText: 'Kommentare',
  refreshQueries: (): CommentType[] => [],
  canDelete: true,
})

const emit = defineEmits<{ (e: 'afterOpenCreatePopup'): void }>()

const popupConfirmDeleteComment = ref<null | ApiCommentGetAll>(null)

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const { comment: queryComment } = useQuery()
const { data: comments, isLoading, isSuccess } = queryComment.allByTypeAndEntityId(computed(() => ({ type: props.type, entityId: props.id })))

const deleteOne = useMutation({
  mutationFn: $trpc.comment.delete.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Kommentar konnte nicht gelöscht werden' }),
  onSuccess: async () => {
    const invalidateQueriesPromises = []

    invalidateQueriesPromises.push(queryClient.invalidateQueries({ queryKey: ['comment'] }))
    invalidateQueriesPromises.push(queryClient.invalidateQueries({ queryKey: [commentConfigs[props.type].entity] }))
    props.refreshQueries.forEach((query) => {
      invalidateQueriesPromises.push(queryClient.invalidateQueries({ queryKey: [commentConfigs[query].entity] }))
    })

    await Promise.all(invalidateQueriesPromises)

    popupConfirmDeleteComment.value = null
    notification.success({ title: 'Der Kommentar wurde gelöscht', duration: 4500 })
  },
})

const { useremail, isRole } = useAuthorization()
const isAuthorOfComment = (comment: ApiCommentGetAll) => useremail.value === comment.user.email

const { openCommentCreateOrUpdatePopup } = useGlobalOpeners()

function openCommentCreate() {
  openCommentCreateOrUpdatePopup.open({ type: props.type, id: props.id, isClosable: props.isClosable, refreshQueries: props.refreshQueries, mode: 'create' })
  emit('afterOpenCreatePopup')
}
function openCommentUpdate(commentId: string) {
  openCommentCreateOrUpdatePopup.open({ type: props.type, id: props.id, isClosable: true, refreshQueries: props.refreshQueries, mode: 'update', commentId })
}

const commentTitle = computed(() => {
  const base = props.listTitleText
  if (comments.value !== undefined) {
    return `${base} (${comments.value.length})`
  }

  return base
})
</script>

<template>
  <div>
    <TheConfirmPopup v-if="popupConfirmDeleteComment" @confirm="deleteOne.mutate(popupConfirmDeleteComment)" @close="popupConfirmDeleteComment = null">
      Der Kommentar und alle zugehörigen Daten werden gelöscht.
    </TheConfirmPopup>

    <p v-if="!hideListTitle" class="font-semibold mb-1">
      {{ commentTitle }}
    </p>
    <div v-if="isLoading">
      Kommentare werden geladen...
    </div>
    <div v-else-if="isSuccess">
      <div v-if="comments && comments.length > 0" class="flex flex-col gap-y-2">
        <div v-for="comment of comments" :key="comment.id" class="border w-full rounded p-2">
          <div class="flex justify-between">
            <span class="font-semibold">{{ useDateAsString(comment.createdAt) }}</span>
            <div class="space-x-2">
              <n-button v-if="isAuthorOfComment(comment)" ghost circle size="small" @click="() => openCommentUpdate(comment.id)">
                <template #icon>
                  <Icon name="material-symbols:edit-outline-rounded" />
                </template>
              </n-button>
              <n-button v-if="(isAuthorOfComment(comment) || isRole('admin')) && canDelete" ghost circle size="small" @click="popupConfirmDeleteComment = comment">
                <template #icon>
                  <Icon name="material-symbols:delete-outline-rounded" />
                </template>
              </n-button>
            </div>
          </div>
          <p class="font-semibold">
            <span v-if="comment.user.role === 'customer-user'">[Kunde]</span>
            {{ comment.user.name }} ({{ comment.user.email }})
          </p>
          <p v-if="comment.text">
            {{ comment.text }}
          </p>
          <div v-if="comment.files.length" class="mt-1">
            <FileView :files="comment.files" collapsed-by-default />
          </div>
        </div>
      </div>
      <div v-else>
        Es gibt noch keine Kommentare.
      </div>
    </div>
    <div v-else>
      Kommentare konnten nicht geladen werden
    </div>

    <div v-if="!isReadOnly" class="mt-2">
      <n-button @click="openCommentCreate">
        Neuen Kommentar erstellen
      </n-button>
    </div>
  </div>
</template>
