import type { DehydratedState, VueQueryPluginOptions } from '@tanstack/vue-query'
import { QueryCache, QueryClient, VueQueryPlugin, dehydrate, hydrate } from '@tanstack/vue-query'
import { useState } from '#app'

export default defineNuxtPlugin((nuxt) => {
  const vueQueryState = useState<DehydratedState | null>('vue-query')

  const errorNotification = (error: unknown, errorKey: string) => {
    const { status } = useAuth()

    const notification = useNaiveNotification()
    const content = 'Operation Fehlgeschlagen'
    const duration = 5 * 1000

    if (status.value !== 'authenticated') {
      notification.create({
        type: 'error',
        duration,
        content,
        description: 'Sie müssen eingeloggt sein um diese Funktionalität zu verwenden',
      })
      return
    }

    notification.create({
      type: 'error',
      duration,
      content,
      description: `${errorKey} konnte(n) nicht geladen werden${error ? ` (Fehler: ${error})` : ''}`,
    })
  }

  // Modify your Vue Query global settings here
  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 5000 } },
    queryCache: new QueryCache({
      onError: (error, query) => errorNotification(
        error,
        // `errorKey` either doesn't exist OR is a string (see `useQuery.ts`), so we can safely lie to typescript at this one point
        query.meta?.errorKey ? (query.meta.errorKey as string) : 'Unbekannte Daten',
      ),
    }),
  })
  const options: VueQueryPluginOptions = { queryClient }

  nuxt.vueApp.use(VueQueryPlugin, options)

  if (import.meta.server) {
    nuxt.hooks.hook('app:rendered', () => {
      vueQueryState.value = dehydrate(queryClient)
    })
  }

  if (import.meta.client) {
    nuxt.hooks.hook('app:created', () => {
      hydrate(queryClient, vueQueryState.value)
    })
  }
})
