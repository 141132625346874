export type Action =
  | 'connect'
  | 'update'
  | 'updatePrice'
  | 'updateDiscount'
  | 'delete'
  | 'openQrCodePopup'
  | 'openDeliveryPopup'
  | 'record'
  | 'confirm'
  | 'showServiceJob'
  | 'repaired'
  | 'prepare'
  | 'edit'
  | 'showDetails'
  | 'acceptOffer'
  | 'confirmOrder'
  | 'pdf'
  | 'invoiced'
  | 'updateFavorite'
  | 'terminate'
  | 'duplicate'
  | 'issue'
  | 'returnIssue'
  | 'cancel'
  | 'block'
  | 'unblock'
  | 'mail'
  | 'comment'
  | 'mark-as-paid'
  | 'mark-as-sent'
  | 'cancelledInvoicePdf'
  | 'scanQrCode'
  | 'enterId'
  | 'addCosts'
  | 'creditNote'
  | 'updateToLowerPriority'
  | 'updateToHigherPriority'
  | 'discount'
  | 'invoice'
  | 'revertTermination'
  | 'mark-task-as-completed'
  | 'openCollectedPopup'
  | 'extendPositionRentalDays'
  | 'markAsVisible'
  | 'markOfferABAsSent'
  | 'confirmConversion'
  | 'shareWithFreelancer'
  | 'editCustomerStorageCategories'
  | 'printDeliverySlip'
  | 'approve'
  | 'retrieveFromStorage'
  | 'archive'
  | 'unarchive'
  | 'printPalletSlip'
  | 'markAsReady'
  | 'editStorageProjectAddress'
  | 'offload'
  | 'store'
  | 'printBatchQrCode'
  | 'openWashingDocumentationPopup'

interface RenderButtonPreset {
  text?: string
  icon: string
}

const PRESETS: Record<Action, RenderButtonPreset> = {
  'update': { text: 'Ändern', icon: 'material-symbols:settings-outline-rounded' },
  'updatePrice': { text: 'Preis ändern', icon: 'material-symbols:settings-outline-rounded' },
  'updateDiscount': { text: 'Rabatt ändern', icon: 'material-symbols:settings-outline-rounded' },
  'delete': { text: 'Löschen', icon: 'material-symbols:delete-outline-rounded' },
  'openQrCodePopup': { text: 'QR-Code', icon: 'material-symbols:qr-code-rounded' },
  'openDeliveryPopup': { text: 'Übergabe', icon: 'material-symbols:local-shipping-outline-rounded' },
  'record': { text: 'Aufnahme', icon: 'material-symbols:checklist-rounded' },
  'confirm': { text: 'Bestätigen', icon: 'material-symbols:check-circle-outline-rounded' },
  'prepare': { text: 'Vorbereiten', icon: 'material-symbols:add-photo-alternate-outline-rounded' },
  'edit': { text: 'Anpassen', icon: 'material-symbols:add-photo-alternate-outline-rounded' },
  'showServiceJob': { text: 'Service-Auftrag', icon: 'material-symbols:edit-document-outline' },
  'repaired': { text: 'Reparieren', icon: 'material-symbols:check-circle-outline-rounded' },
  'showDetails': { text: 'Details', icon: 'material-symbols:info-outline-rounded' },
  'acceptOffer': { text: 'zu Auftrag', icon: 'material-symbols:fact-check-outline-rounded' },
  'confirmOrder': { text: 'Auftrag für Lieferung freigeben', icon: 'material-symbols:fact-check-outline-rounded' },
  'pdf': { icon: 'material-symbols:description-outline-rounded' },
  'mail': { icon: 'material-symbols:mail-outline-rounded' },
  'invoiced': { text: 'Rechnungen', icon: useBrandIcons().invoice },
  'terminate': { text: 'Abmelden', icon: 'material-symbols:tab-close-outline-rounded' },
  'duplicate': { text: 'Kopieren', icon: 'material-symbols:content-copy-outline-rounded' },
  'issue': { text: 'Ausgeben', icon: 'material-symbols:keyboard-double-arrow-right-rounded' },
  'returnIssue': { text: 'Zurücknehmen', icon: 'material-symbols:keyboard-double-arrow-left-rounded' },
  'cancel': { text: 'Stornieren', icon: 'material-symbols:cancel-outline' },
  'connect': { text: 'Verbindungen verwalten', icon: 'material-symbols:add-link' },
  'block': { text: 'Blockieren', icon: 'material-symbols:lock-outline' },
  'unblock': { text: 'Freigeben', icon: 'material-symbols:lock-open-outline' },
  'comment': { text: 'Kommentar', icon: 'material-symbols:comment-outline-rounded' },
  'mark-as-paid': { text: 'Bezahlung bestätigen', icon: 'material-symbols:paid-outline-rounded' },
  'mark-as-sent': { text: 'Versand bestätigen', icon: 'material-symbols:airplane-ticket-outline-rounded' },
  'cancelledInvoicePdf': { text: 'Storniert', icon: 'material-symbols:description-outline-rounded' },
  'scanQrCode': { text: 'Gerät Scannen', icon: 'material-symbols:qr-code-scanner' },
  'enterId': { text: 'Manuell Id eingeben', icon: 'material-symbols:add-box-outline-rounded' },
  'addCosts': { text: 'Kosten hinzufügen', icon: 'material-symbols:euro' },
  'creditNote': { text: 'Rechnungskorrektur erstellen', icon: 'material-symbols:settings-outline-rounded' },
  'updateToLowerPriority': { text: 'Aufschieben', icon: 'material-symbols:arrow-downward-alt-rounded' },
  'updateToHigherPriority': { text: 'Priorisieren', icon: 'material-symbols:arrow-upward-alt-rounded' },
  'discount': { text: 'Abziehen', icon: 'material-symbols:percent' },
  'invoice': { text: 'Abrechnen', icon: 'material-symbols:euro' },
  'revertTermination': { text: 'Abmeldung rückgängig machen', icon: 'material-symbols:history' },
  'openCollectedPopup': { text: 'Details zur Rücknahme', icon: 'material-symbols:feature-search-outline-rounded' },
  'extendPositionRentalDays': { text: 'Mietdauer verländern', icon: 'material-symbols:alarm-add-outline-rounded' },
  'markAsVisible': { text: 'Kommentar anzeigen', icon: 'material-symbols:visibility-outline-rounded' },
  'confirmConversion': { text: 'Umrüstung bestätigen', icon: 'material-symbols:check-circle-outline-rounded' },
  'markOfferABAsSent': { text: 'AB Versand bestätigen', icon: 'material-symbols:airplane-ticket-outline-rounded' },
  'shareWithFreelancer': { text: 'Mit Freelancer teilen', icon: 'material-symbols:share-windows' },
  'editCustomerStorageCategories': { text: 'Lagerkategorien', icon: 'material-symbols:box-edit-outline-sharp' },
  'printDeliverySlip': { text: 'Lieferschein drucken', icon: 'material-symbols:receipt-outline' },
  'approve': { text: 'Bestätigen', icon: 'material-symbols:check-circle-outline' },
  'retrieveFromStorage': { text: 'Auslagern', icon: 'material-symbols:outbox-outline-rounded' },
  'unarchive': { text: 'Wiederherstellen', icon: 'material-symbols:unarchive-outline-rounded' },
  'archive': { text: 'Archivieren', icon: 'material-symbols:archive-outline-rounded' },
  'printPalletSlip': { text: 'Palettenbegleitschein drucken', icon: 'material-symbols:print-outline-rounded' },
  'markAsReady': { text: 'Bereitstellen', icon: 'material-symbols:library-add-check-outline' },
  'editStorageProjectAddress': { text: 'Lieferadressen verwalten', icon: '' },
  'offload': { text: 'Abladen', icon: 'material-symbols:forklift-outline-rounded' },
  'store': { text: 'Einlagern', icon: 'material-symbols:warehouse-outline-rounded' },

  // Adding separate emit function in TableView gives a warning message in console
  // So the new emit function should be added to Action and used
  'updateFavorite': { text: '', icon: '' },

  'mark-task-as-completed': { text: 'Abschließen', icon: 'material-symbols:check-circle-outline' },
  'printBatchQrCode': { text: 'Alle QR Codes drucken', icon: 'material-symbols:qr-code-rounded' },
  'openWashingDocumentationPopup': { text: 'Doku Waschen', icon: '' },
}

export default (): typeof PRESETS => PRESETS
