import theme from '#tailwind-config/theme'

export default defineAppConfig({
  // See https://nuxt-naiveui.bg.tn/components/naive-config#naiveconfig
  naiveui: {
    themeConfig: {
      shared: {
        common: {
          primaryColor: theme.colors.primary.DEFAULT,
          primaryColorHover: theme.colors.primary['400'],

          borderRadius: theme.borderRadius.DEFAULT,
        },
        Tooltip: {
          color: 'white',
          textColor: 'black',
        },
        DataTable: {
          thColor: theme.colors.gray['100'],
          thTextColor: theme.colors.black,
          thFontWeight: theme.fontWeight.semibold,
          thIconColor: theme.colors.black,
          tdColorHover: theme.colors.gray['100'],
          loadingSize: '40px',
          opacityLoading: '50%',
          borderColor: theme.colors.gray['200'],
        },
      },
    },
  },
})
