<script lang="ts" setup>
import type { CreateDefect, OfferType, UpdateDefect, UpdateOrCreateDefect } from '~/types'

const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const { openDefectCreateOrUpdatePopup: { data: popupData, close: closePopup } } = useGlobalOpeners()
const id = computed(() => {
  if (!popupData.value) {
    return
  }

  if (popupData.value.mode === 'update') {
    return popupData.value.id
  }
})

const { defect: defectQuery } = useQuery()
const { data: defect, isLoading: isLoadingDefect } = defectQuery.byId(id)

const payload = computed((): UpdateOrCreateDefect | undefined => {
  if (!popupData.value) {
    return undefined
  }
  if (popupData.value.mode === 'create') {
    return {
      mode: 'create',
      data: {
        offerId: popupData.value.offerId,
        description: '',
        photos: [],
        priority: 0,
        type: popupData.value.type,
        ...(popupData.value.type === 'machinery'
          ? { machineryId: popupData.value.itemId }
          : { machineryAccessoryId: popupData.value.itemId }),
      },
    } as CreateDefect
  }

  if (defect.value && !isLoadingDefect.value) {
    return {
      mode: 'update',
      data: defect.value,
    } as UpdateDefect
  }
})

const update = useMutation({
  mutationFn: $trpc.defect.update.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Defekt konnte nicht aktualisiert werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['defect'] }),
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
    ])
    closePopup()
    notification.success({ title: 'Der Defekt wurde erfolgreich aktualisiert', duration: 4500 })
  },
})

const create = useMutation({
  mutationFn: $trpc.defect.create.mutate,
  onError: makeTrpcErrorToast(notification, { description: 'Der Defekt konnte nicht erstellt werden' }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['defect'] }),
      queryClient.invalidateQueries({ queryKey: ['machines'] }),
    ])
    closePopup()
    notification.success({ title: 'Der Defekt wurde erfolgreich erstellt', duration: 4500 })
  },
})

function saveCreateOrEditForm(payload: UpdateOrCreateDefect) {
  if (payload.mode === 'create') {
    return create.mutateAsync(payload.data)
  } else {
    return update.mutateAsync(payload.data)
  }
}

const isReadOnly = computed(() => {
  if (!defect.value) {
    return false
  }

  return defect.value.repairedAt !== null
})

const { openOfferPrintPopup } = useGlobalOpeners()
</script>

<template>
  <ThePopup v-if="payload && popupData" :show="Boolean(payload)" :title="`${popupData.type === 'machinery' ? 'Geräte' : 'Lagertool'}zustand ${popupData.itemId} ${popupData.mode === 'create' ? 'erstellen' : 'erstellen'}`" width="90%" @close="closePopup">
    <CommentList v-if="payload.mode === 'update' && defect" :id="payload.data.id" type="defect" class="mb-5" />

    <p v-if="defect?.offerId && defect?.offer" class="my-4">
      Dieser Defekt ist in {{ defect.offerId }} entstanden.
      <span
        class="underline text-blue-800 cursor-pointer"
        @click=" openOfferPrintPopup.open({
          offerId: defect.offer.id,
          offerCuid: defect.offer.cuid,
          type: defect.offer.type as OfferType,
        })"
      >
        Klicken Sie hier um die Auftrags-Dokumente einzusehen
      </span>
    </p>
    <DefectCreateOrEditForm :payload="payload" :is-read-only="isReadOnly" @save="saveCreateOrEditForm" />
  </ThePopup>
</template>
