<script setup lang="ts">
import type { ApiMachineryGetById } from '~/types'

const props = defineProps<{ machinery: ApiMachineryGetById }>()

const { partnerSales } = useQuery()
const { data } = partnerSales.getReleaseConditionsForMachineryForPartner(props.machinery.id)
</script>

<template>
  <n-alert v-if="data?.isSpecialOffer" type="warning">
    Bitte beachte, bei dieser Maschine handelt es sich um ein Sonderangebot!
  </n-alert>

  <n-alert v-if="data" type="info">
    <p>Dieses Gerät wurde Ihnen zum Verkauf freigegeben.</p>
    <p>- UVP: {{ machinery.priceRecommendedToSellFor ? useDisplayNumberAsCurrency(machinery.priceRecommendedToSellFor) : 'N/A' }}</p>
    <p>- Dein EK-Preis: {{ useDisplayNumberAsCurrency(data.pricePartnerHasToPurchaseFor) }}</p>
    <p v-if="data.note" class="my-2">
      {{ data.note }}
    </p>
    <p class="my-2">
      Sie können rechts oben Schnellangebote erstellen.
    </p>
  </n-alert>
</template>
