<script lang="ts" setup>
import type { Size } from './common'
import { getUploadedFile } from './common'
import type { FilePath, UploadedFile } from '~/types'

const props = withDefaults(defineProps<{
  files: (string | FilePath)[]
  label?: string
  labelFont?: string
  size?: Size
  collapsedByDefault?: boolean | 'if-empty'
}>(), {
  label: undefined,
  labelFont: 'font-semibold',
  size: 'Normal',
  collapsedByDefault: false,
})

const uploadedFiles = computed((): UploadedFile[] => props.files.map(getUploadedFile))

const defaultExpandedNames = computed(() => {
  if (props.collapsedByDefault === true) {
    return []
  }

  if (props.collapsedByDefault === false) {
    return ['photos']
  }

  // The following must be true by reason of type-narrowing at this point: `collapsedByDefault === 'if-empty'`
  const isFilesEmpty = props.files.length === 0
  if (isFilesEmpty) {
    return []
  }

  return ['photos']
})
</script>

<template>
  <div>
    <n-collapse :default-expanded-names>
      <n-collapse-item name="photos">
        <template #header>
          <span :class="`flex ${labelFont}`">{{ props.label ?? 'Dateien' }} ({{ props.files.length }})</span>
        </template>

        <div v-if="uploadedFiles.length === 0">
          <n-empty class="py-5" description="Keine Dateien hochgeladen" />
        </div>
        <FileListWithPreviews
          v-else
          :files="uploadedFiles"
          :size="size"
        />
      </n-collapse-item>
    </n-collapse>
  </div>
</template>
