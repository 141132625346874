import type { UserRole } from '~/authorization'
import type { OfferType } from '~/types'

type PositionInputPermission = 'action-buttons' | 'move-position' | 'delete-position' | 'position-fields' | 'pdfs'
const serviceProjectPositionPermissions: Record<PositionInputPermission, UserRole[]> = {
  'action-buttons': ['admin', 'rental-employee', 'department-lead', 'project-manager', 'service-project-employee'],
  'move-position': ['admin', 'rental-employee', 'department-lead', 'project-manager', 'service-project-employee'],
  'delete-position': ['admin', 'rental-employee', 'department-lead', 'project-manager', 'service-project-employee'],
  'position-fields': ['admin', 'rental-employee', 'department-lead', 'project-manager', 'service-project-employee'],
  'pdfs': ['admin', 'rental-employee', 'department-lead', 'project-manager', 'service-project-employee'],
}

export function hasPositionPermission(permission: PositionInputPermission, role: UserRole, offerType?: OfferType) {
  if (!offerType || offerType !== 'service-project') {
    return true
  }
  return serviceProjectPositionPermissions[permission].includes(role)
}
