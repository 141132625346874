<script lang="ts" setup>
import { createLocalStoragePlugin } from '@formkit/addons'
import type { LocalStorageOptions } from '@formkit/addons'
import type { ReceptionMachineryTireType } from '@prisma/client'
import { equipmentType, machineryGears, machineryHals, machineryMasts, machineryMastsForBuehnen, machinerySteeringType, machineryTireColors, receptionMachineryTireCountSchema } from '~/server/schemas'
import { machineryFemsWithFiveAndNone } from '~/server/schemas/machineryAndMachineryAccessoryCommon'
import type { ApiMachineryDriveGetAll, ApiMachineryTypeGetAll, ApiReceptionMachinerySpecialEquipment, MachineryCategories, MachineryDrives, UpdateOrCreateReceptionMachinery } from '~/types'
import { gearOptionsToGerman, machineryDrivesToGerman } from '~/translations'

const props = defineProps<{ payload: UpdateOrCreateReceptionMachinery, specialEquipments: ApiReceptionMachinerySpecialEquipment[], tireTypes: ReceptionMachineryTireType[], machineryTypes: ApiMachineryTypeGetAll[], machineryDrives: ApiMachineryDriveGetAll[], localStorageKey?: LocalStorageOptions, createdAccessoryId?: string, localStorageDisabled?: boolean, disabled?: boolean }>()
const emit = defineEmits<{ (e: 'save', payload: UpdateOrCreateReceptionMachinery): void }>()
const { cloned } = useCloned(props.payload)

const { drive, machineryTire, machineryBattery, machineryMotor, machinerySpecialEquipments } = toRefs(cloned.value.data)
const { category } = cloned.value.data.receptionMachinery

const { machineryHeadingsByMachineCategory: showMachineryHeadingsByMachineCategory, machineryFieldsByMachineCategory: showMachineryFieldsByMachineCategory, machineryPropulsionFieldsByMachineCategory: showMachineryPropulsionFieldsByMachineCategory, machineryFieldsNotRequiredByMachineCategory } = useFormOptionalConditions()

function useIsRequiredByFilters(filters: Record<string, MachineryCategories[]>, field: string, category: MachineryCategories) {
  return !filters[field].includes(category)
}

// Set default value for `hasAdBlue` if applicable
if (useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'hasAdBlue', category, drive.value)) {
  machineryMotor.value.hasAdBlue = machineryMotor.value.hasAdBlue ?? false
}

const selectedAmpere = ref<SelectOption>()
const actualAmpere = ref<number>()

// Ampere Options
const quickOptions = [80, 160, 320, 640] as const

type SelectOption = keyof typeof quickOptions | 'other'
type Options = { [I in SelectOption]: string }
const machineryAmpereOptions: Options = Object.fromEntries(quickOptions.map(v => [v, `${v}A`]).concat([['other', 'Sonstiges']]))

function onAmpereChange(v: SelectOption) {
  if (v === 'other') {
    actualAmpere.value = 0
  } else {
    actualAmpere.value = Number.parseInt(v as string, 10) || 0
  }
}

onMounted(() => {
  const ampere = cloned.value.data.machineryBattery.ampere
  if (ampere) {
    actualAmpere.value = ampere
    if (!quickOptions.includes(ampere as typeof quickOptions[number])) {
      selectedAmpere.value = 'other'
    }
  }
})

const machinerySpecialEquipmentOptions = computed(() => props.specialEquipments.filter(equipment => equipment.supportCategories?.includes(category)).map(equipment => ({
  label: equipment.typeName,
  value: equipment.id,
  equipmentType: equipment.equipmentType,
})))

const specialEquipment = computed(() => machinerySpecialEquipmentOptions.value.reduce((accumulator: Record<string, string[]>, currentEquipment) => {
  if (accumulator[currentEquipment.equipmentType]) {
    accumulator[currentEquipment.equipmentType].push(...machinerySpecialEquipments.value.filter(equip => equip === currentEquipment.value))
  } else {
    accumulator[currentEquipment.equipmentType] = machinerySpecialEquipments.value.filter(equip => equip === currentEquipment.value)
  }
  return accumulator
}, {}))

const machineryTireTypeOptions = computed(() => props.tireTypes.map(type => ({
  label: type.typeName,
  value: type.id,
})))

const machineryGearOptions = machineryGears.options.map((gear) => {
  return {
    label: gearOptionsToGerman[gear],
    value: gear,
  }
})

const machineryTypeOptions = computed(() => props.machineryTypes.filter(type => type.category === category).map(type => ({
  label: `${type.name}`,
  value: type.id,
})))

const machineryDrivesOptions = computed(() => props.machineryDrives.map(drive => ({
  label: machineryDrivesToGerman[drive.name as MachineryDrives],
  value: drive.id,
})))

function submit() {
  const specialEquipments: string[] = []
  Object.values(specialEquipment.value).forEach((values) => {
    if (!values) {
      return
    }
    values.forEach(equipment => specialEquipments.push(equipment))
  })
  machinerySpecialEquipments.value = specialEquipments

  cloned.value.data.machineryBattery.ampere = actualAmpere.value

  emit('save', cloned.value)
}

const { openAccessoryCreateOrEditPopup } = useGlobalOpeners()

const formkitPlugins = useFormkitPlugins()

const allFormkitPlugins = computed(() => {
  const plugins = [formkitPlugins]
  if (!props.localStorageDisabled) {
    plugins.push(createLocalStoragePlugin(props.localStorageKey))
  }
  return plugins
})
</script>

<template>
  <FormKit
    v-slot="{ value }"
    v-model="cloned.data.receptionMachinery"
    name="MachineryCreationReceptionProtocolForm"
    type="form"
    :plugins="allFormkitPlugins"
    use-local-storage
    :config="{ validationVisibility: 'submit' }"
    :disabled="disabled"
    @submit="submit"
  >
    <TheDevOnlyNiceJson :value="value" :cloned="cloned" />
    <div class="w-full flex flex-col gap-y-2 mb-6">
      <FormKit
        v-if="cloned.data.receptionMachinery.machineryId"
        id="machineryId"
        label="ID"
        type="text"
        :disabled="true"
      />
      <h2 class="Heading">
        Fahrzeugdaten
      </h2>
      <div class="flex gap-x-2 flex-wrap">
        <div class="WidthHalfMinusGap">
          <FormKit
            id="yearBuilt"
            label="Baujahr"
            type="number"
            placeholder="2022"
            validation="min:1900|max:2030"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="chassisNumber"
            label="Fahrgestell-Nr"
            type="text"
            placeholder="57374"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="serialNumber"
            label="Eingest Serien Nr."
            type="text"
            placeholder="12313KFR"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKit
            id="product"
            label="Fabrikat"
            type="text"
            placeholder="Magni"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="driveId"
            :value="cloned.data.receptionMachinery.driveId ? cloned.data.receptionMachinery.driveId : undefined"
            type="select"
            :options="machineryDrivesOptions"
            label="Antrieb"
            placeholder="Elektro"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKit
            id="liftingWeight"
            label="Tragkraft (kg)"
            type="number"
            step="0.01"
            placeholder="1800"
          />
        </div>

        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'loadCenter', category)" class="WidthHalfMinusGap">
          <FormKit
            id="loadCenter"
            label="Lastschwerpunkt"
            type="text"
            placeholder="40 – 600 amps"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="opticalCondition"
            label="Zustand (optisch)"
            type="text"
            placeholder="Neu"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKit
            id="technicalCondition"
            label="Zustand (technisch)"
            type="text"
            placeholder="Neu"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="weight"
            label="Eigengewicht (kg)"
            type="number"
            step="0.01"
            placeholder="27000"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKitMultiSelect
            id="typeId"
            label="Typ"
            :multi-select-options="machineryTypeOptions"
            :is-multi-select-allowed="false"
            placeholder="H16/900-02, 2021"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKit
            id="typeAddition"
            label="Typ Zusatz"
            type="text"
            placeholder="H16/900-02, 2021"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="initialOperatingHours"
            label="Betriebsstunden"
            type="number"
            step="0.1"
            placeholder="10"
            validation-visibility="live"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="key"
            label="Schlüssel"
            type="text"
            placeholder="2x MC74"
          />
        </div>
      </div>

      <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'platform', category)" class="w-full flex flex-col gap-y-2">
        <h2 class="Heading">
          Plattform
        </h2>
        <div class="flex gap-x-2 flex-wrap">
          <div class="WidthHalfMinusGap">
            <FormKit
              id="platformLengthInMillimeters"
              label="Länge (mm)"
              type="number"
              step="0.01"
              placeholder="1000"
            />
          </div>
          <div class="WidthHalfMinusGap">
            <FormKit
              id="platformWidthInMillimeters"
              label="Breite (mm)"
              type="number"
              step="0.01"
              placeholder="1500"
            />
          </div>

          <div class="WidthHalfMinusGap">
            <FormKit
              id="platformHeightInMillimeters"
              label="Höhe (mm)"
              type="number"
              step="0.01"
              placeholder="1300"
            />
          </div>
        </div>
      </div>

      <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'mastdaten', category)">
        <h2 class="Heading">
          Mastdaten / Maße
        </h2>
        <div class="flex gap-x-2 flex-wrap">
          <div class="WidthHalfMinusGap">
            <FormKit
              id="mast"
              label="Mast"
              type="select"
              :options="category === 'Bühne' ? machineryMastsForBuehnen.options : machineryMasts.options"
              :placeholder="machineryMasts.options[0]"
            />
          </div>
          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'workHeightInMillimeters', category)" class="WidthHalfMinusGap">
            <FormKit
              id="workHeightInMillimeters"
              label="Arbeitshöhe (mm)"
              type="number"
              step="0.01"
              placeholder="2000"
            />
          </div>

          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'liftingHeightInMillimeters', category)" class="WidthHalfMinusGap">
            <FormKit
              id="liftingHeightInMillimeters"
              label="Hubhöhe (mm)"
              type="number"
              step="0.01"
              placeholder="5000"
            />
          </div>
          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'sideReachInMillimeters', category)" class="WidthHalfMinusGap">
            <FormKit
              id="sideReachInMillimeters"
              label="Seitliche Reichweite (mm)"
              type="number"
              step="0.01"
              placeholder="2400"
            />
          </div>
          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'mastOverallHeightInMillimeters', category)" class="WidthHalfMinusGap">
            <FormKit
              id="mastOverallHeightInMillimeters"
              label="Bauhöhe (mm)"
              type="number"
              step="0.01"
              placeholder="3300"
            />
          </div>
          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'freeLiftInMillimeters', category)" class="WidthHalfMinusGap">
            <FormKit
              id="freeLiftInMillimeters"
              label="Freihub (mm)"
              type="number"
              step="0.01"
              placeholder="1620"
            />
          </div>

          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'forkCarriageWidthInMillimeters', category)" class="WidthHalfMinusGap">
            <FormKit
              id="forkCarriageWidthInMillimeters"
              label="Gabelträgerbreite (mm)"
              type="number"
              step="0.01"
              placeholder="1500"
            />
          </div>

          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'fem', category)" class="WidthHalfMinusGap">
            <FormKit
              id="fem"
              label="FEM-Klasse"
              type="select"
              :options="machineryFemsWithFiveAndNone.options"
              :placeholder="machineryFemsWithFiveAndNone.options[0]"
              validation="required"
            />
          </div>
          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'hals', category)" class="WidthHalfMinusGap">
            <FormKit
              id="hals"
              label="Hals"
              type="select"
              :options="machineryHals.options"
              :placeholder="machineryHals.options[0]"
            />
          </div>
          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'addOns', category)" class="WidthHalfMinusGap">
            <FormKit
              id="addOns"
              label="Zusatz"
              type="text"
              placeholder="Klammer Glas"
            />
          </div>
          <div v-if="category === 'Stapler' && payload.mode === 'create'" class="WidthHalfMinusGap flex items-center">
            <n-button
              v-if="!createdAccessoryId"
              style="width: 100%"
              @click="openAccessoryCreateOrEditPopup.open({ mode: 'create', category: 'fork', isCreatedIdRequired: true })"
            >
              Verbundene Gabeln anlegen
            </n-button>
            <n-button
              v-else
              style="width: 100%"
              @click="openAccessoryCreateOrEditPopup.open({ mode: 'update', id: createdAccessoryId, isUpdatingFromReception: true })"
            >
              Verbundene Gabeln {{ createdAccessoryId }} bearbeiten
            </n-button>
          </div>
        </div>
      </div>
      <h2 class="Heading">
        Sonderausstattung
      </h2>
      <div class="flex gap-x-2 flex-wrap">
        <div v-for="type of equipmentType.options" :key="type" class="WidthHalfMinusGap">
          <FormKitMultiSelect
            v-model="specialEquipment[type]"
            :label="type"
            :multi-select-options="machinerySpecialEquipmentOptions.filter(equipment => equipment.equipmentType === type)"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKit
            id="customSpecialEquipment"
            label="Individuelle Sonderausstattung"
            type="text"
            placeholder="Sonderausstattung"
          />
        </div>
      </div>

      <h2 class="Heading">
        Abmessung
      </h2>
      <div class="flex gap-x-2 flex-wrap">
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'lengthToForkfaceInMillimeters', category)" class="WidthHalfMinusGap">
          <FormKit
            id="lengthToForkfaceInMillimeters"
            label="Länge bis zum Gabelträger (mm)"
            type="number"
            step="0.01"
            placeholder="2500"
          />
        </div>
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'totalLengthInMillimeters', category)" class="WidthHalfMinusGap">
          <FormKit
            id="totalLengthInMillimeters"
            label="Gesamtlänge (mm)"
            type="number"
            step="0.01"
            placeholder="2500"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKit
            id="wheelbaseInMillimeters"
            label="Radstand (mm)"
            type="number"
            step="0.01"
            placeholder="2000"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="overallWidthInMillimeters"
            label="Baubreite (mm)"
            type="number"
            step="0.01"
            placeholder="4800"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKit
            id="groundClearanceCenter"
            label="Bodenfreiheit Mitte"
            type="text"
            placeholder="300"
          />
        </div>

        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'cabinHeightInMillimeters', category)" class="WidthHalfMinusGap">
          <FormKit
            id="cabinHeightInMillimeters"
            label="Höhe Kabine (mm)"
            type="number"
            step="0.01"
            placeholder="950"
          />
        </div>
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'dimensionHeightInMillimeters', category)" class="WidthHalfMinusGap">
          <FormKit
            id="dimensionHeightInMillimeters"
            label="Höhe (mm)"
            type="number"
            step="0.01"
            placeholder="2500"
          />
        </div>
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'overallHeightInMillimeters', category)" class="WidthHalfMinusGap">
          <FormKit
            id="overallHeightInMillimeters"
            label="Bauhöhe (mm)"
            type="number"
            step="0.01"
            placeholder="3000"
          />
        </div>
      </div>

      <h2 class="Heading">
        Bereifung
      </h2>
      <div class="flex gap-x-2 flex-wrap">
        <FormKit v-model="machineryTire" type="group">
          <div class="WidthHalfMinusGap">
            <FormKit
              id="producerCompanyName"
              label="Hersteller"
              type="text"
              placeholder="Hyster"
            />
          </div>
          <div class="WidthHalfMinusGap">
            <FormKit
              id="typeName"
              label="Typ"
              type="text"
              placeholder="Diagonal"
            />
          </div>

          <div class="WidthHalfMinusGap">
            <FormKit
              id="color"
              label="Farbe"
              type="select"
              :options="machineryTireColors.options"
              :placeholder="machineryTireColors.options[0]"
            />
          </div>
          <div class="WidthHalfMinusGap">
            <FormKitMultiSelect
              id="tireTypes"
              v-model="cloned.data.machineryTireTypes"
              label="Art"
              :multi-select-options="machineryTireTypeOptions"
            />
          </div>
          <FormKit
            v-if="cloned.data.receptionMachinery.category === 'Stapler'"
            id="tireCount"
            label="Räder-Anzahl"
            type="select"
            :options="receptionMachineryTireCountSchema.options"
            :placeholder="receptionMachineryTireCountSchema.options[0]"
          />

          <h3 class="SubHeading">
            Bereifung vorne
          </h3>
          <div class="flex gap-x-2 flex-wrap">
            <div class="WidthHalfMinusGap">
              <FormKit
                id="frontSize"
                label="Größe"
                type="text"
                placeholder="16.5"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="frontCount"
                label="Anzahl"
                type="number"
                placeholder="2"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="frontCondition"
                label="Zustand"
                type="text"
                placeholder="gut"
              />
            </div>
          </div>

          <h3 class="SubHeading">
            Bereifung hinten
          </h3>
          <div class="flex gap-x-2 flex-wrap">
            <div class="WidthHalfMinusGap">
              <FormKit
                id="rearSize"
                label="Größe"
                type="text"
                placeholder="16.5"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="rearCount"
                label="Anzahl"
                type="number"
                placeholder="2"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="rearCondition"
                label="Zustand"
                type="text"
                placeholder="Neu"
              />
            </div>
          </div>
        </FormKit>
      </div>

      <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'antrieb', category)">
        <h2 class="Heading">
          Antrieb
        </h2>
        <FormKit v-model="machineryMotor" type="group">
          <div class="flex gap-x-2 flex-wrap">
            <div v-if="useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'producerCompanyName', category, drive)" class="WidthHalfMinusGap">
              <FormKit
                id="producerCompanyName"
                label="Motor Hersteller"
                type="text"
                placeholder="Automatik-Flanschkugelhahn"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="power"
                label="Leistung (KW)"
                type="number"
                step="0.01"
                placeholder="38"
              />
            </div>
            <div v-if="useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'typeName', category, drive)" class="WidthHalfMinusGap">
              <FormKit
                id="typeName"
                label="Typ"
                type="text"
                placeholder="PK04"
              />
            </div>
            <div v-if="useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'gear', category, drive)" class="WidthHalfMinusGap">
              <FormKit
                id="gear"
                label="Getriebe"
                type="select"
                :options="machineryGearOptions"
              />
            </div>
            <div v-if="useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'serialNumber', category, drive)" class="WidthHalfMinusGap">
              <FormKit
                id="serialNumber"
                label="Motorseriennummer"
                type="text"
                placeholder="19393ADB"
              />
            </div>
            <div v-if="useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'gearProducer', category, drive)" class="WidthHalfMinusGap">
              <FormKit
                id="gearProducer"
                label="Getriebe Hersteller"
                type="text"
                placeholder="Automatik"
              />
            </div>
            <div v-if="useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'hasAdBlue', category, drive)" class="WidthHalfMinusGap">
              <FormKit
                id="hasAdBlue"
                label="AdBlue"
                type="checkbox"
                outer-class="flex h-full items-center"
              />
            </div>
          </div>
        </FormKit>
      </div>

      <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'batteriedaten', category)">
        <h2 class="Heading">
          Batteriedaten
        </h2>
        <FormKit v-model="machineryBattery" type="group">
          <div class="flex gap-x-2 flex-wrap">
            <div class="WidthHalfMinusGap">
              <FormKit
                id="producerCompanyName"
                label="Hersteller"
                type="text"
                placeholder="PANTHER"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="typeName"
                label="Typ"
                type="text"
                placeholder="Panther Premium LKW"
              />
            </div>

            <div class="WidthHalfMinusGap">
              <FormKit
                id="weight"
                label="Gewicht (kg)"
                type="number"
                step="0.01"
                placeholder="22"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="volt"
                label="Volt"
                type="text"
                placeholder="14.8"
              />
            </div>

            <div class="WidthHalfMinusGap">
              <FormKit
                id="yearBuilt"
                label="Baujahr"
                type="number"
                placeholder="2019"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="serialNumber"
                label="Seriennummer"
                type="text"
                placeholder="19393ADB"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="ampere"
                v-model="selectedAmpere"
                label="Ampere"
                type="select"
                placeholder="80A"
                :options="machineryAmpereOptions"
                @input="(v: SelectOption) => onAmpereChange(v)"
              />
            </div>
            <div v-if="selectedAmpere === 'other'" class="WidthHalfMinusGap">
              <FormKit
                v-model="actualAmpere"
                min="0"
                type="number"
                number="integer"
                label="Sonstiges Ampere"
                placeholder="100"
              />
            </div>
          </div>
        </FormKit>
      </div>

      <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'lenkung', category)" class="w-full flex flex-col gap-y-2">
        <h2 class="Heading">
          Lenkung
        </h2>
        <div class="flex gap-x-2 flex-wrap">
          <div class="WidthHalfMinusGap">
            <FormKit
              id="steeringType"
              label="Lenkart"
              type="select"
              :options="machinerySteeringType.options"
              :placeholder="machinerySteeringType.options[0]"
            />
          </div>
        </div>
      </div>

      <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'weitereInfos', category)" class="w-full flex flex-col gap-y-2">
        <h2 class="Heading">
          Weitere Infos
        </h2>
        <div class="flex gap-x-2 flex-wrap">
          <div class="WidthHalfMinusGap">
            <FormKit
              id="extraAddOns"
              label="Zusatz"
              type="text"
              placeholder="Klammer Glas"
            />
          </div>
          <div class="WidthHalfMinusGap">
            <FormKit
              id="extraAccessories"
              label="Zubehör"
              type="text"
              placeholder="shovel"
            />
          </div>
        </div>
      </div>

      <h2 class="Heading">
        Fotos
      </h2>
      <div class="w-full space-y-2">
        <FormKitFileDropzone
          id="basicDataPhotos"
          label="Grunddaten"
          :read-only="disabled"
          :disabled="false"
        />
        <FormKitFileDropzone
          id="generalPhotos"
          label="Allgemeine Fotos (Mindestens 2)"
          :read-only="disabled"
          :disabled="false"
        />
        <FormKitFileDropzone
          v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'cabinInsidePhotos', category)"
          id="cabinInsidePhotos"
          :label="`Kabinen Fotos - innen ${useIsRequiredByFilters(machineryFieldsNotRequiredByMachineCategory, 'cabinInsidePhotos', category) ? '(Mindestens 2)' : '(Empfohlen 2)'} `"
          :read-only="disabled"
          :disabled="false"
        />
        <FormKitFileDropzone
          v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'cabinOutsidePhotos', category)"
          id="cabinOutsidePhotos"
          :label="`Kabinen Fotos - außen ${useIsRequiredByFilters(machineryFieldsNotRequiredByMachineCategory, 'cabinOutsidePhotos', category) ? '(Mindestens 2)' : '(Empfohlen 2)'} `"
          :read-only="disabled"
          :disabled="false"
        />
        <FormKit v-model="machineryTire" type="group">
          <FormKitFileDropzone
            v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'tiresPhotos', category)"
            id="photos"
            label="Reifen Fotos (Mindestens 2)"
            :read-only="disabled"
            :disabled="false"
          />
        </FormKit>
        <FormKit v-model="machineryBattery" type="group">
          <FormKitFileDropzone
            v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'batteryPhotos', category, drive)"
            id="photos"
            label="Batterie / Ladegerät Fotos (Mindestens 1)"
            :read-only="disabled"
            :disabled="false"
          />
        </FormKit>
        <FormKit v-model="machineryMotor" type="group">
          <FormKitFileDropzone
            v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'motorPhotos', category, drive)"
            id="photos"
            label="Motor Fotos (Mindestens 1)"
            :read-only="disabled"
            :disabled="false"
          />
        </FormKit>
        <FormKitFileDropzone
          id="videos"
          label="Videos"
          :read-only="disabled"
          :disabled="false"
        />
      </div>

      <n-collapse v-if="cloned.data.receptionMachinery.machineryId">
        <n-collapse-item>
          <template #header>
            <span class="text-lg font-bold">
              Zustand
            </span>
          </template>
          <div class="flex w-full gap-x-4 gap-y-2">
            <DefectPage :create-mode-options="{ itemId: cloned.data.receptionMachinery.machineryId, type: 'machinery' }" />
          </div>
        </n-collapse-item>
      </n-collapse>
    </div>
  </FormKit>
</template>

<style scoped>
.Heading {
  @apply text-xl font-bold py-4
}
.SubHeading {
  @apply text-lg font-bold py-4
}
.WidthHalfMinusGap {
  width: calc(50% - 0.5rem)
}

.CheckboxStyle {
  @apply flex pt-4 items-center
}
.RemoveCheckboxBackground :deep(.formkit-inner) {
  background: none;
}
</style>
