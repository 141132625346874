<script setup lang="ts">
import type { ApiMachineryGetById } from '~/types'

defineProps<{ payload: ApiMachineryGetById }>()
const emit = defineEmits<{ (e: 'save', data: ApiMachineryGetById): void }>()
</script>

<template>
  <FormKit
    type="form"
    :value="payload"
    submit-label="Speichern"
    @submit="(data: ApiMachineryGetById) => emit('save', data)"
  >
    <FormKit
      name="priceRecommendedToSellFor"
      type="number"
      number="float"
      step="0.01"
      min="0"
      label="UVP (EUR)"
    />
    <FormKit
      name="priceHanselmannPubliclySellsFor"
      type="number"
      number="float"
      step="0.01"
      min="0"
      label="Hanselmann Online VK-Preis (EUR)"
    />
  </FormKit>
</template>
