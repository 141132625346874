<script setup lang="ts">
import { differenceInDays, endOfDay, startOfDay, startOfMonth, startOfYear, subDays } from 'date-fns'

const props = defineProps<{ defaultDurationDays?: DurationOptionValue }>()
const today = new Date()
const durationDaysYearToDate = differenceInDays(today, startOfYear(today))
const durationDaysMonthToDate = differenceInDays(today, startOfMonth(today))
const durationDaysTodayStartToEnd = differenceInDays(today, endOfDay(today))

const valuesToDuration = {
  7: 7,
  30: 30,
  90: 90,
  180: 180,
  365: 365,
  todayStartToEnd: durationDaysTodayStartToEnd,
  monthToDate: durationDaysMonthToDate,
  yearToDate: durationDaysYearToDate,
  manual: 0,
} as const
type DurationOptionValue = keyof typeof valuesToDuration

const dateStart = ref<null | number>(null)
const dateEnd = ref<null | number>(null)
watch(() => ({ dateStart: dateStart.value, dateEnd: dateEnd.value }), ({ dateStart, dateEnd }) => {
  if (!dateStart || !dateEnd) {
    return
  }

  setModelValue([dateStart, dateEnd])
})

const selectedPreset = ref<DurationOptionValue>(props.defaultDurationDays ?? 'yearToDate')
watch(selectedPreset, (newPreset, oldPreset) => {
  if (newPreset === oldPreset) {
    return
  }

  if (newPreset === 'manual') {
    return
  }

  const presetValue = valuesToDuration[newPreset]

  dateStart.value = subDays(today, presetValue).getTime()
  dateEnd.value = today.getTime()
}, { immediate: true })

const modelValue = defineModel<[number, number] | null>()
function setModelValue([v1, v2]: [number, number]) {
  modelValue.value = [
    startOfDay(v1).getTime(),
    endOfDay(v2).getTime(),
  ]
}

const durationPresets: { label: string, value: DurationOptionValue }[] = [
  {
    label: 'Manuell',
    value: 'manual',
  },
  {
    label: 'Letzte 7 Tage',
    value: 7,
  },
  {
    label: 'Letzte 30 Tage',
    value: 30,
  },
  {
    label: 'Letzte 90 Tage',
    value: 90,
  },
  {
    label: 'Letzte 180 Tage',
    value: 180,
  },
  {
    label: 'Letzte 365 Tage',
    value: 365,
  },
  {
    label: 'Heute (Tagesanfang bis -ende)',
    value: 'todayStartToEnd',
  },
  {
    label: 'Monatsanfang bis heute',
    value: 'monthToDate',
  },
  {
    label: 'Jahresanfang bis heute',
    value: 'yearToDate',
  },
]
</script>

<template>
  <div class="mb-4">
    <div class="flex flex-col md:flex-row gap-2">
      <label class="italic w-full md:w-1/3">
        Zeitraum:
        <n-select v-model:value="selectedPreset" :options="durationPresets" />
      </label>
      <div class="flex gap-2 w-full">
        <label class="italic grow">
          Start:
          <n-date-picker
            :value="dateStart"
            :disabled="selectedPreset !== 'manual'"
            class="!w-full"
            type="date"
            clearable
            @update:value="v => dateStart = v"
          />
        </label>
        <label class="italic grow">
          Ende:
          <n-date-picker
            :value="dateEnd"
            :disabled="selectedPreset !== 'manual'"
            class="!w-full"
            type="date"
            clearable
            @update:value="v => dateEnd = v"
          />
        </label>
      </div>
    </div>
  </div>
</template>
