<script setup lang="ts">
withDefaults(defineProps<{
  modelValue: string
  placeholder?: string
  isLoading?: boolean
}>(), {
  placeholder: 'Suchbegriff eingeben (Nach Tabellen-Feldern)',
})

const emit = defineEmits<{ (e: 'update:modelValue', value: string): void, (e: 'blur'): void }>()
</script>

<template>
  <div>
    <div class="flex flex-col my-2 gap-2">
      <n-input
        class="Input"
        :value="modelValue"
        :placeholder="placeholder"
        :loading="isLoading"
        clearable
        @update:value="val => emit('update:modelValue', val)"
        @blur="emit('blur')"
      >
        <template #prefix>
          <Icon name="material-symbols:search-rounded" size="20" />
        </template>
      </n-input>
      <slot name="search" />
    </div>
    <slot />
  </div>
</template>
