import { z } from 'zod'
import { zObject } from '~/server/schemas/zObject'

export const idSchema = z.string().min(1).max(36)
export const idObjectSchema = zObject({
  id: idSchema,
})
export const idObjectDuplicateSchema = zObject({
  id: idSchema,
  customId: idSchema.optional(),
})

export const emailSchema = z.coerce.string().email()
export const emailObjectSchema = zObject({
  email: emailSchema,
})

export const fileSchema = zObject({ name: z.string(), type: z.string() })

/**
 * Outdated file-approach we want to move away from. `filesFieldSchema`, `zodArrayAsString` can be removed when all other places updated to the new file approach (see: https://github.com/sidestream-tech/hanselmann-os/pull/2359)
 * @deprecated
 */
const filesFieldSchema = z.array(z.string())

/**
 * Outdated file-approach we want to move away from. `filesFieldSchema`, `zodArrayAsString` can be removed when all other places updated to the new file approach (see: https://github.com/sidestream-tech/hanselmann-os/pull/2359)
 * @deprecated
 */
export const filesFieldSchemaNullish = filesFieldSchema.nullish()

/**
 * Zod parser that validates and transforms an array into a stringified array (to save string array into database eg. files, photos, ...)
 *
 * TODO: This can be removed once all fields are switched over to propper arrays. (wrap-file-in-object transition)
 * @deprecated
 */
export const zodArrayAsString = z.array(z.string()).nullish().transform(val => val ? useArrayAsString(val) : '')

// Needed for updated file approach, where files are stored in their own table. Introduced in https://github.com/sidestream-tech/hanselmann-os/pull/2359
const pathInObjectFileSchema = zObject({ path: z.string() })
export const pathInObjectFilesSchema = pathInObjectFileSchema.array().default(() => [])
