// This file is for sharing schemas with `machinery` and `machineryAccessory` schemas
import { z } from 'zod'

export const machineryFemsOptions = ['1', '2', '3', '4', 'Terminal West', 'Welle', 'Rollengeführt'] as const
export const machineryFemsWithFiveOptions = [...machineryFemsOptions, '5'].sort() as [string, ...string[]]

export const machineryFems = z.enum(machineryFemsOptions)
export const machineryFemsAndNone = z.enum([...machineryFemsOptions, 'Keine'])
export const machineryFemsAndFive = z.enum(machineryFemsWithFiveOptions)
export const machineryFemsWithFiveAndNone = z.enum([...machineryFemsWithFiveOptions, 'Keine'])

export const machineryColors = ['Weiß', 'Schwarz', 'Grau', 'Beige'] as const
export const machineryTireColors = z.enum(machineryColors)
